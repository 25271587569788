import React from "react";

import { Container } from "@mui/material";

import WarningBox from "../../components/boxes/WarningBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormInformarSituacaoContato from "../../components/formularios/ParceiroCidadaoInformarSituacaoContato";
import TitlePage from "../../components/title-page/TitlePage";

const PageInformarSituacaoContato = () => {
  const handleSubmit = async values => {
    alert(JSON.stringify(values));
  };

  const linksBreadCrumb = [
    { href: "/", text: "Parceiro" },
    { href: "/microcredito", text: "Microcrédito" },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        currentPage="Informar situação de contato"
        links={linksBreadCrumb}
      />

      <WarningBox />

      <TitlePage title={"Informar situação de contato"} />

      <FormInformarSituacaoContato handleSubmit={handleSubmit}></FormInformarSituacaoContato>
    </Container>
  );
};

export default PageInformarSituacaoContato;
