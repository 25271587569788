import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import FormCurso from "../../components/formularios/FormCurso";
import TitlePage from "../../components/title-page/TitlePage";

const PageEditarCurso = () => {
  const value = {
    cnpjOfertante: 0,
    nomeOfertante: "Teste",
    cargaHoraria: "0",
    turno: "DIU",
    nomeDoCurso: "Teste",
    escolaridade: "EM",
    tipoDeCurso: "SUP",
    pronatec: "S",
    pre_requisitos: ["Ensino Fundamental", "Ensino Médio"],
    inicio_matricula: null,
    fim_matricula: null,
    idade_min: "00",
    idade_max: "100",
    modalidade: "PRS",
    trilhas: "Teste",
    inicio_curso: null,
    fim_curso: null,
    link: "http://teste.com.br",
    cep: "44444-444",
    uf: "DF",
    endereco: "Teste",
    numero: "00",
    complemento: "Teste",
    bairro: "Teste",
    municipio: "Brasília",
    telefone: "0000-0000",
    vagas: "100",
    abrangencia: "M",
    uf_abrangencia: "DF",
    municipio_abrangencia: "Brasília",
    ementa: "Teste",
    descricao: "Teste",
    vigencia: "Teste",
    ultima_modificacao: "01/01/2024",
    situacao: "Teste",
    motivo_situacao: "Teste",
  };
  const handleSubmit = values => {
    console.log(values);
  };

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/cursos", text: "Cursos" }]}
        currentPage={"Editar"}
      />

      <TitlePage title={"Editar Curso"} />

      <FormCurso
        valores={value}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

export default PageEditarCurso;
