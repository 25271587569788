import React, { useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";

import { st_escolaridade } from "../../../services/constants.js";

const valoresIniciais = {
  escolaridade: "",
  situacao: "",
  curso: "",
  conclusao: "",
  instituicaoEnsino: "",
};

const FormEscolaridade = ({ initialValues = valoresIniciais }) => {
  const [formList, setFormList] = useState([initialValues, initialValues]);

  const addNewForm = () => {
    setFormList([...formList, initialValues]);
  };

  return (
    <div>
      {formList.map((formValues, index) => (
        <Formik
          key={index}
          initialValues={formValues}
          validateOnChange={false}
        >
          {({ values, handleChange, handleBlur }) => (
            <Form>
              <Stack>
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormControl fullWidth>
                      <InputLabel>Escolaridade / Grau de instrução</InputLabel>
                      <Select
                        label="Escolaridade / Grau de instrução"
                        id="escolaridade"
                        name="escolaridade"
                        value={values.escolaridade}
                        placeholder="Insira sua escolaridade (ex: Ensino médio incompleto)"
                        onChange={handleChange}
                        type="text"
                      >
                        <MenuItem
                          key={""}
                          name={""}
                        >
                          Selecione a Escolaridade
                        </MenuItem>
                        {Object.keys(st_escolaridade).map(value => (
                          <MenuItem
                            key={value}
                            value={value}
                          >
                            {st_escolaridade[value]}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Situação"
                        id={`situacao-${index}`}
                        name="situacao"
                        value={values.situacao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                  >
                    <FormGroup>
                      <TextField
                        label="Curso"
                        id={`curso-${index}`}
                        name="curso"
                        value={values.curso}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormGroup>
                      <TextField
                        label="Conclusão"
                        id={`conclusao-${index}`}
                        name="conclusao"
                        value={values.conclusao}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                  >
                    <FormGroup>
                      <TextField
                        label="Instituição de ensino"
                        id={`instituicaoEnsino-${index}`}
                        name="instituicaoEnsino"
                        value={values.instituicaoEnsino}
                        placeholder=""
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </FormGroup>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{ mt: 3, mb: 3 }}
                  >
                    <FormGroup style={{ borderBottom: "1px solid LightGrey" }}></FormGroup>
                  </Grid>
                </Grid>
              </Stack>
            </Form>
          )}
        </Formik>
      ))}
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="outlined"
            onClick={addNewForm}
            sx={{ mt: 2 }}
            startIcon={<AddCircleIcon />}
          >
            ADICIONAR ESCOLARIDADE
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormEscolaridade.propTypes = {
  initialValues: PropTypes.shape({
    escolaridade: PropTypes.string,
    situacao: PropTypes.string,
    curso: PropTypes.string,
    conclusao: PropTypes.string,
    instituicaoEnsino: PropTypes.string,
  }),
};

export default FormEscolaridade;
