const parceiroNavConfig = [
  {
    title: "Página Inicial",
    path: "/parceiro",
    color: "default",
    subTitles: {
      home: "/",
      perfil: "/profile",
    },
  },
  {
    title: "parceiro",
    path: "/parceiro",
    subTitles: {
      "Nova Inscrição de Parceiro": "/parceiro/cadastro",
      "Listar Parceiros": "/parceiro/listar-parceiros",
    },
  },
  {
    title: "Vagas",
    path: "/vagas",
    subTitles: {
      "Cadastrar Vaga": "/parceiro/vagas/cadastrar-vaga",
      "Listar Vagas": "/parceiro/vagas/listar-vagas",
      "Buscar Currículos": "/parceiro/vagas/buscar-curriculo",
    },
  },
  {
    title: "Cursos",
    path: "/curos",
    subTitles: {
      "Novo Curso (HU023)": "/parceiro/cursos/novo",
      "Editar Curso (HU033)": "/parceiro/cursos/editar",
      "Visualizar Curso (HU034)": "/parceiro/cursos/visualizar",
      "Listar Cursos (HU024, HU035)": "/parceiro/cursos/listar",
      "Relação de Interessados (HU036)": "/parceiro/cursos/interessados",
      "Duplicar Curso (HU037)": "/parceiro/cursos/duplicar",
    },
  },
  {
    title: "Mobilização",
    path: "/mobilizacao",
    subTitles: {
      "Encontre Currículos em sua Região": "/parceiro/mobilizacao/curriculos/buscar",
      "Visualizar Currículo Mobilização (HU060)": "/parceiro/mobilizacao/curriculo/visualizar",
      //"Interesses da Pessoa (HU111)": "/parceiro/mobilizacao/interesses-da-pessoa",
      "Mobilização Individual do Cidadão (HU113)": "/parceiro/mobilizacao/mobilizacao-individual-cidadao",
      //"Curriculo do Cidadão (HU114)": "/parceiro/mobilizacao/curriculo-cidadao",
      //"Editar Currículo do Cidadão (HU115)": "/parceiro/mobilizacao/editar-curriculo-cidadao",
      "Listar Vagas na Região do Cidadão (HU122)": "/parceiro/mobilizacao/listar-vagas-regiao-cidadao",
      "Visualizar Informações da Vaga (HU123)": "/parceiro/mobilizacao/visualizar-informacoes-vaga",
      "Mobilização Buscar Cursos (HU052)": "/parceiro/mobilizacao/cursos/buscar",
      "Mobilização Buscar Vagas (HU053)": "/parceiro/mobilizacao/vagas/buscar",
      "Listar Cursos na Região do Cidadão (HU124)": "/parceiro/mobilizacao/listar-cursos-regiao-cidadao",
      "Buscar operador de microcrédito (HU144)": "/parceiro/mobilizacao/listar-mpo/consultar",
      "Novo curso Empreender (HU162)": "/parceiro/mobilizacao/cursos/novo",
    },
  },
  {
    title: "Microcrédito",
    path: "microcredito",
    subTitles: {
      //"Quero Ser Empreendedor (HU151)": "/parceiro/microcredito/quero-ser-empreendedor",
      //"Já Sou Empreendedor (HU152)": "/parceiro/microcredito/ja-sou-empreendedor",
      //"Mobilização Individual - Acesso a Microcrédito Produto Orientado (HU147)": "/parceiro/microcredito/acesso-mpo",
      //"Mobilização Individual - Acesso a Microcrédito Produto Orientado Aprovado (HU149)": "/parceiro/microcredito/acesso-mpo-aprovado",
      //"Microcrédito MPO (HU148)": "/parceiro/microcredito/credito-mpo",
      // "Questionário Microcrédito MPO (HU148)": "/parceiro/microcredito/credito-mpo/questionario",
      //"Informar Situação Contato (HU150)": "/parceiro/microcredito/informar-situacao-contato",
      "Perguntas Frequentes Microcrédito (HU153)": "/parceiro/microcredito/FAQ",
    },
  },
  {
    title: "[DEV] Components",
    path: "/components",
    color: "default",
    subTitles: {
      Components: "/components",
      "Default Table": "/components-default-table",
      "Dynamic Form": "/components-form",
    },
  },
  {
    title: "Menu Temporário",
    path: "/",
    color: "default",
    subTitles: {
      "Nova Inscrição de Parceiro (HU001)": "/parceiro/cadastro",
      "Visualizar Parceiro (não tem HU)": "/parceiro/visualizar",
      "Listar Parceiros  (HU016, HU145)": "/parceiro/listar-parceiros",
      "Excluir Parceiro (HU017)": "/parceiro/excluir",
      "Editar Parceiro (HU018)": "/parceiro/editar",
      "Usuário do Parceiro (HU019)": "/parceiro/usuarios",
      "Novo Curso (HU023)": "/parceiro/cursos/novo",
      "Editar Curso (HU033)": "/parceiro/cursos/editar",
      "Visualizar Curso (HU034)": "/parceiro/cursos/visualizar",
      "Informações Complementares (HU126)": "/parceiro/empresa-complementar/cadastrar",
      "Visualizar Informações Complementares (HU130)": "/parceiro/empresa-complementar/visualizar",
      "Listar Cursos (HU024, HU035)": "/parceiro/cursos/listar",
      "Relação de Interessados (HU036)": "/parceiro/cursos/interessados",
      "Duplicar Curso (HU037)": "/parceiro/cursos/duplicar",
      "Cadastrar Vaga de Trabalho (HU054)": "/parceiro/vagas/cadastrar-vaga",
      "Editar Vaga de Trabalho (HU056)": "/parceiro/vagas/editar-vaga",
      "Duplicar Vaga de Trabalho (HU062)": "/parceiro/vagas/duplicar-vaga",
      "Listar Vagas de Trabalho (HU055), (HU057), (HU061), (HU066)": "/parceiro/vagas/listar-vagas",
      "Listar Pessoas Interessadas (HU059)": "/parceiro/vagas/listar-pessoas-interessadas",
      "Buscar Currículos (HU058)": "/parceiro/vagas/buscar-curriculo",
      "Buscar Currículos Mobilização (HU110)": "/parceiro/mobilizacao/curriculos/buscar",
      "Visualizar Currículo (HU060)": "/parceiro/vagas/visualizar-curriculo",
      "Visualizar Currículo Mobilização (HU060)": "/parceiro/mobilizacao/curriculo/visualizar",
      //"Interesses da Pessoa (HU111)": "/parceiro/mobilizacao/interesses-da-pessoa",
      "Mobilização Individual do Cidadão (HU113)": "/parceiro/mobilizacao/mobilizacao-individual-cidadao",
      //"Curriculo do Cidadão (HU114)": "/parceiro/mobilizacao/curriculo-cidadao",
      //"Editar Currículo do Cidadão (HU115)": "/parceiro/mobilizacao/editar-curriculo-cidadao",
      "Listar Vagas na Região do Cidadão (HU122)": "/parceiro/mobilizacao/listar-vagas-regiao-cidadao",
      "Visualizar Informações da Vaga (HU123)": "/parceiro/mobilizacao/visualizar-informacoes-vaga",
      "Mobilização Buscar Cursos (HU052)": "/parceiro/mobilizacao/cursos/buscar",
      "Mobilização Buscar Vagas (HU053)": "/parceiro/mobilizacao/vagas/buscar",
      "Listar Cursos na Região do Cidadão (HU124)": "/parceiro/mobilizacao/listar-cursos-regiao-cidadao",
      "Visualizar Informações Curso (HU125)": "/parceiro/mobilizacao/visualizar-informacoes",
      //"Quero Ser Empreendedor (HU151)": "/parceiro/microcredito/quero-ser-empreendedor",
      //"Já Sou Empreendedor (HU152)": "/parceiro/microcredito/ja-sou-empreendedor",
      "Listar Interessados em MPO (HU143)": "/parceiro/listar-mpo",
    },
  },
];

export default parceiroNavConfig;
