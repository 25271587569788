import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Card, Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import LoadingComponent from "../../components/loading/LoadingComponent";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import adminServices from "../../services/admin.service";

const PageVisualizarAlteracoesDocumentos = () => {
  //const navigate = useNavigate();
  let { id } = useParams();
  // TODO: remover esse default
  id = id || "1";

  const [documentos, setDocumentos] = useState([]);

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: files,
    error,
  } = useQuery({
    queryKey: ["arquivosEmpresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return adminServices.getDocumentosEmpresaParceira(id);
    },
    enabled: !!id,
  });

  if (isError) {
    console.error("Erro de carregamento: ", error);
  }

  useEffect(() => {
    if (files) {
      setDocumentos(Array.isArray(files) ? files : []);
    }
  }, [files]);

  // const documentosAlterados = [
  //   {
  //     dataModificacao: "10/10/2004",
  //     momento: "Teste",
  //     nomeArquivo: "Currículo",
  //   },
  // ];

  const colunas = [
    { field: "dataModificacao", headerName: "Data", sxProps: { width: "200px" } },
    { field: "momento", headerName: "Momento", sxProps: { width: "200px" } },
    { field: "nomeArquivo", headerName: "Nome do Arquivo", sxProps: { width: "200px" } },
  ];

  const getTabelaActions = () => [
    {
      title: "Download",
      icon: "download",
      href: "",
      onClick: () => {
        // Pegar o nome para o arquivo
        const fileName = prompt("Escolha um nome para o arquivo", `documento.pdf`);
        // TODO: Conseguir pegar o id da linha atual (não funcionou com rowId)
        const base64Data = documentos[0].base64;
        // "data:text/plain;base64,SGVsbG8sIFdvcmxkIQ==";  // "Hello, World!" in base64 format

        // Create a link element
        const a = document.createElement("a");

        // Define o nome do arquivo
        a.download = fileName;

        // Set the href of the link to the base64 data
        a.href = base64Data;

        // Append the link to the body
        document.body.appendChild(a);

        // Trigger the download
        a.click();

        // Remove the link after download
        document.body.removeChild(a);
      },
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "/parceiro/listar/pendentes", text: "Listar Parceiros Pendentes" },
          { href: "/parceiro/analisar/", text: "Analisar Parceiro Pendente" },
        ]}
        currentPage="Informações alteradas"
      />

      <TitlePage title={"Anexos na aprovação e reprovação"} />

      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        {isPending ? (
          <LoadingComponent
            isLoading={isPending}
            fullScreen={false}
          />
        ) : isError ? (
          <ErroCarregamentoModal isShow={true} />
        ) : (
          <DefaultTable
            columns={colunas}
            rows={documentos}
            actionButtons={getTabelaActions}
          />
        )}
      </Card>
    </Container>
  );
};

export default PageVisualizarAlteracoesDocumentos;
