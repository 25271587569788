import React from "react";

import PropTypes from "prop-types";

import AdminParceiroFormInformacoesComplementares from "../../components/formularios/AdminParceiroFormInformacoesComplementares";
import CustomModal from "./CustomModal";

const InformacoesComplementaresModal = ({ showModal, handleClose, handleSubmit, initialValues }) => {
  const modalTitle = "Serviço Financeiros e Pagamentos";

  const modalContent = (
    <>
      <AdminParceiroFormInformacoesComplementares
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleCancelar={handleClose}
      />
    </>
  );

  const modalButtons = [];

  return (
    <CustomModal
      showModal={showModal}
      handleClose={handleClose}
      title={modalTitle}
      content={modalContent}
      buttons={modalButtons}
      maxWidth="lg"
    />
  );
};

InformacoesComplementaresModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleContinuar: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  setCheckFinanceiro: PropTypes.func,
};

export default InformacoesComplementaresModal;
