import React from "react";

import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";

const InformationBox = ({ title, message }) => {
  return (
    <Box
      sx={{
        border: "1px solid lightgrey",
        borderRadius: "5px",
        padding: "16px",
        display: "flex",
        alignItems: "flex-start",
        marginTop: "16px",
        backgroundColor: "white",
      }}
    >
      <InfoIcon style={{ marginRight: "8px", color: "lightgrey" }} />
      <div>
        <Typography
          variant="subtitle1"
          sx={{ fontWeight: "bold", marginBottom: "4px" }}
        >
          {title}
        </Typography>
        <Box sx={{ typography: "body2" }}>
          <div>{message}</div>
        </Box>
      </div>
    </Box>
  );
};

InformationBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.node,
};

export default InformationBox;
