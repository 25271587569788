import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, Stack, TextField, Typography } from "@mui/material";

import AuthService from "../../services/auth.service";
import { FormFieldsGrid } from "../formularios/DynamicForm";

const ForgotPassword = () => {
  const navigate = useNavigate();

  //const email = useParams().email;

  const {
    control,
    handleSubmit,
    // setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { email: "" } });

  const resetSenha = () => {
    AuthService.postPasswordReset(getValues().email)
      .then(response => {
        navigate("/parceiro_login");
        //TODO: Colocar a pagina de redirecionamento adequada
        alert(response.message);
      })
      .catch(error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        alert(resMessage);
      })
      .finally(() => {
        navigate("/parceiro_login");
      });
  };

  //resetSenha(email);

  const fields = [
    // Senha
    {
      name: "email",
      label: "E-mail",
      validation: {
        required: "Campo de e-mail é obrigatório",
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "O e-mail deve ser válido",
        },
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="email"
          name="email"
          label="E-mail"
          fullWidth
          type={"string"}
          placeholder="E-mail"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12 },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: 1 }}
        >
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 625,
              height: 1,
              maxHeight: 700,
            }}
          >
            <Typography variant="h3">Solicitação de nova senha</Typography>
            <Typography variant="body1">Informe seu e-mail para o envio das instruções para recuperação de senha.</Typography>

            <form onSubmit={handleSubmit(resetSenha)}>
              <FormFieldsGrid
                fields={fields}
                control={control}
                errors={errors}
              />

              <Grid
                container
                spacing={1}
                mt={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{ gap: "8px" }}
                  >
                    <Typography variant={"BUTTON TEXT"}>ENVIAR E-MAIL</Typography>
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate("/parceiro_login")}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </form>

            <center>
              <Typography variant="caption">
                Caso permanessa com problemas, entre em contato conosco pelo e-mail{" "}
                <Link
                  component="a"
                  //href=
                  // Incluir o redirecionamento adequado
                  color="primary.main"
                  underline="always"
                  sx={{ ml: 1 }}
                >
                  cgad@mds.gov.br
                </Link>
              </Typography>
            </center>
          </Card>
        </Stack>
      </Box>
    </>
  );
};

export default ForgotPassword;
