import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeProvider } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Card, CardContent, FormGroup, Grid, Stack, Typography } from "@mui/material";
import { createTheme, useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import adminService from "../../services/admin.service";
import ErroCarregamentoModal from "../errors/ErrorModal";
import LoadingComponent from "../loading/LoadingComponent";
import InformacoesParceiroModal from "../modals/InformacoesParceiroModal";
import ServicoOfertadoModal from "../modals/ServicoOfertadoModal";
import DefaultTable from "../table/DefaultTable";

let idSelecionado = 0;

// Retornar a pesquisa vazia
const initialFilterState = {
  tipoDeServico: [],
  dataCadastroInicio: null,
  dataCadastroFim: null,
  dataUltimaModificacaoInicio: null,
  dataUltimaModificacaoFim: null,
};

const initialCores = {
  0: "naoSelecionado",
  1: "naoSelecionado",
  2: "naoSelecionado",
  3: "naoSelecionado",
  4: "naoSelecionado",
  5: "naoSelecionado",
  6: "naoSelecionado",
};

const tabelaColunas = [
  { field: "habilitacao", headerName: "Habilitação", sxRowProps: { textTransform: "uppercase", color: "primary.main" } },
  { field: "status", headerName: "Status" },
];

const keysHidden = [
  "dataCadastro",
  "dataUltimaModificacao",
  "cnpj",
  "nomeFantasia",
  "razaoSocial",
  "naturezaJuridica",
  "nomeResponsavel",
  "email",
  "nomePontoFocal",
  "emailPontoFocal",
  "telefone",
  "complemento",
  "uf",
  "cidade",
  "endereco",
];

const filterDataByStatus = (data, statuses) => {
  return data.filter(item => statuses.includes(item.status));
};

const AdminListarParceirosAprovados = ({ servicosModal, setServicosModal, informacoesModal, setInformacoesModal }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(initialFilterState);
  const [filteredData, setFilteredData] = useState([]);
  const [parceiroSelecionado, setParceiroSelecionado] = useState({});

  // Conexão com o back-end
  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresasParceirasAprovadas"],
    queryFn: adminService.getEmpresasParceiras,
  });

  useEffect(() => {
    if (initialData) {
      setFilteredData(filterDataByStatus(Array.isArray(initialData) ? initialData : [], ["Aprovado"]));
    }
  }, [initialData]);

  const tabelaHiddenLinhas = useMemo(
    () =>
      (Array.isArray(filteredData) ? filteredData : []).map(row =>
        keysHidden.reduce((acc, key) => {
          acc[key] = row[key];
          return acc;
        }, {}),
      ),
    [filteredData, keysHidden],
  );

  const theme = useTheme();
  const isDark = theme.palette.mode == "dark";

  const tema = createTheme({
    palette: {
      naoSelecionado: {
        main: isDark ? "#02b1c4" : "#FFFFFF",
        dark: isDark ? "#017a87" : "#D9D9D9",
      },
      selecionado: {
        main: isDark ? "#64a367" : "#8AE38F",
        dark: isDark ? "#4d7d50" : "#77C77B",
      },
    },
  });

  const [cores, setCores] = useState(initialCores);

  const applyFilter = () => {
    const filtered = initialData.filter(parceiro => {
      if (parceiro.situacao == "Aprovado") {
        const matchesTextFilter =
          filter.tipoDeServico.length === 0 ||
          Object.keys(parceiro.tipoDeServico)
            .map(servico => {
              return parceiro.tipoDeServico[servico] == true && filter.tipoDeServico.includes(servico);
            })
            .includes(true);

        const matchesDateFilter =
          (!filter.dataCadastroInicio ||
            !filter.dataCadastroFim ||
            dayjs(parceiro.dataCadastro).isBetween(filter.dataCadastroInicio, filter.dataCadastroFim, "day", "[]")) &&
          (!filter.dataUltimaModificacaoInicio ||
            !filter.dataUltimaModificacaoFim ||
            dayjs(parceiro.dataUltimaModificacao).isBetween(
              filter.dataUltimaModificacaoInicio,
              filter.dataUltimaModificacaoFim,
              "day",
              "[]",
            ));
        return matchesTextFilter && matchesDateFilter;
      }
    });
    setFilteredData(filtered);
  };

  const resetFilter = () => {
    setFilter(initialFilterState);
    setCores(initialCores);
    applyFilter();
  };

  // Visualizar informações do parceiro
  const handleOpenInformacoesModal = id => {
    setParceiroSelecionado(filteredData.filter(parceiro => parceiro.id == id)[0]);
    setInformacoesModal(true);
  };

  const handleCloseInformacoesModal = () => {
    setInformacoesModal(false);
  };

  // Visualizar serviços ofertados pelo parceiro
  const handleListaOpen = id => {
    idSelecionado = id;
    setServicosModal(true);
  };

  const handleListaClose = () => {
    setServicosModal(false);
  };

  const selectServico = event => {
    const { id, name } = event.target;
    if (filter["tipoDeServico"].includes(name)) {
      setFilter({ ...filter, ["tipoDeServico"]: filter["tipoDeServico"].filter(nome => nome !== name) });
      setCores({ ...cores, [id]: "naoSelecionado" });
    } else {
      setFilter({ ...filter, ["tipoDeServico"]: filter["tipoDeServico"].concat([name]) });
      setCores({ ...cores, [id]: "selecionado" });
    }
  };

  const getTabelaActions = () => [
    {
      title: "Serviços Ofertados",
      icon: "list",
      onClick: rowId => handleListaOpen(rowId),
    },
    {
      title: "Reprovar",
      icon: "cancel",
      onClick: rowId => navigate("/parceiro/analisar/" + rowId),
    },
    {
      title: "Visualizar",
      icon: "visibility",
      onClick: rowId => handleOpenInformacoesModal(rowId),
    },
    {
      title: "Documentos Anexos",
      icon: "attach_file",
      onClick: rowId => navigate("/parceiro/alteracoes-documentos/visualizar/" + rowId),
    },
  ];

  if (!filteredData || isPending) {
    return (
      <LoadingComponent
        isLoading={isPending}
        fullScreen={false}
      />
    );
  }

  if (isError) {
    console.log("Erro de carregamento:", error);
    return <ErroCarregamentoModal isShow={true} />;
  }

  return (
    <>
      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
            mt: "24px",
          }}
        >
          {/* Campos para filtrar */}
          <Grid
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle2">Filtros</Typography>
            </Grid>
          </Grid>
          <ThemeProvider theme={tema}>
            <Grid container>
              <Grid
                item
                xs={2.8}
              >
                <Button
                  id={0}
                  name="VEP"
                  variant="contained"
                  color={cores[0]}
                  onClick={selectServico}
                >
                  Vaga de Emprego
                </Button>
              </Grid>
              <Grid
                item
                xs={2.7}
              >
                <Button
                  id={1}
                  name="VET"
                  variant="contained"
                  color={cores[1]}
                  onClick={selectServico}
                >
                  Vaga de Estágio
                </Button>
              </Grid>
              <Grid
                item
                xs={3.6}
              >
                <Button
                  id={2}
                  name="VJA"
                  variant="contained"
                  color={cores[2]}
                  onClick={selectServico}
                >
                  Vaga de Jovem Aprendiz
                </Button>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <Button
                  id={3}
                  name="CUR"
                  variant="contained"
                  color={cores[3]}
                  onClick={selectServico}
                >
                  Cursos
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              mt={1}
            >
              <Grid
                item
                xs={4.3}
              >
                <Button
                  id={4}
                  name="FPG"
                  variant="contained"
                  color={cores[4]}
                  onClick={selectServico}
                >
                  Financeiros e de Pagamentos
                </Button>
              </Grid>
              <Grid
                item
                xs={3.7}
              >
                <Button
                  id={5}
                  name="MPU"
                  variant="contained"
                  color={cores[5]}
                  onClick={selectServico}
                >
                  Mobilização de Público
                </Button>
              </Grid>
              <Grid
                item
                xs={4}
              >
                <Button
                  id={6}
                  name="MPA"
                  variant="contained"
                  color={cores[6]}
                  onClick={selectServico}
                >
                  Mobilização de Parceiro
                </Button>
              </Grid>
            </Grid>
          </ThemeProvider>

          <Grid
            mt={2}
            spacing={3}
            container
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data do Cadastro</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Início"
                            id="dataCadastroInicio"
                            name="dataCadastroInicio"
                            value={filter.dataCadastroInicio}
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataCadastroInicio"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataCadastroFim"
                            name="dataCadastroFim"
                            value={filter.dataCadastroFim}
                            label="Fim"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataCadastroFim"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <Card style={{ border: "1px solid #d3d3d3" }}>
                <CardContent>
                  <p style={{ fontFamily: "Rawline Regular", color: "grey" }}>Data da última modificação</p>
                  <Grid
                    container
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataUltimaModificacaoInicio"
                            name="dataUltimaModificacaoInicio"
                            value={filter.dataUltimaModificacaoInicio}
                            label="Início"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoInicio"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                    >
                      <FormGroup>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            id="dataUltimaModificacaoFim"
                            name="dataUltimaModificacaoFim"
                            value={filter.dataUltimaModificacaoFim}
                            label="Fim"
                            format="DD/MM/YYYY"
                            onChange={valor => setFilter({ ...filter, ["dataUltimaModificacaoFim"]: valor })}
                          />
                        </LocalizationProvider>
                      </FormGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Botões de pesquisa/cancelar */}
          <Box sx={{ flexGrow: 1, mt: "16px" }}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-end"
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={resetFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={applyFilter}
                  sx={{ gap: "8px" }}
                  style={{ fontFamily: "Rawline Medium" }}
                >
                  <SearchIcon />
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              rows={filteredData}
              notFoundText={"Não foi localizado Parceiro na situação aprovado!"}
              columns={tabelaColunas}
              hiddenRows={tabelaHiddenLinhas}
              actionButtons={getTabelaActions}
            ></DefaultTable>
          </Box>
        </Card>
      </Stack>
      <div>
        {/* Modal para listar Serviços*/}
        <ServicoOfertadoModal
          showModal={servicosModal}
          handleClose={handleListaClose}
          tipoDeServico={filteredData.filter(parceiro => parceiro.id == idSelecionado)[0]?.tipoDeServico}
        />
        {/* Modal para visualizar informações do Parceiro*/}
        <InformacoesParceiroModal
          showModal={informacoesModal}
          handleClose={handleCloseInformacoesModal}
          parceiro={parceiroSelecionado}
        />
      </div>
    </>
  );
};

AdminListarParceirosAprovados.propTypes = {
  setServicosModal: PropTypes.func,
  servicosModal: PropTypes.bool,
  informacoesModal: PropTypes.bool,
  setInformacoesModal: PropTypes.func,
};

export default AdminListarParceirosAprovados;
