import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import constantsService from "../../services/constants.service";
import { formatCNPJ, formatTelefone } from "../formularios/utils.js";
import FileUpload from "../inputs/fileUpload";
import InformacoesParceiroModal from "../modals/InformacoesParceiroModal";

const AdminAnalisarParceiroPendente = ({ valores, id, onSubmit }) => {
  const theme = useTheme();
  const smDown = useResponsive("down", "sm");

  const [informacoesModal, setInformacoesModal] = useState(false);
  const [localFiles, setLocalFiles] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: valores,
    mode: "onSubmit",
  });

  const handleFiles = (newFiles, setFieldValue) => {
    setLocalFiles(newFiles);
    setFieldValue("anexo", newFiles);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={1}>
          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
            }}
          >
            {/* Nome e CNPJ */}
            <Grid
              spacing={1}
              container
            >
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="nomePontoFocal"
                    name="nomePontoFocal"
                    label="Nome"
                    placeholder="Nome"
                    value={valores.nomePontoFocal}
                    type="text"
                  />
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    placeholder="CNPJ"
                    value={formatCNPJ(valores.cnpj) || ""}
                    type="text"
                  />
                </FormGroup>
              </Grid>
            </Grid>

            {/* Datas e telefone */}
            <Grid
              spacing={1}
              container
              marginTop={1}
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Data do Cadastro"
                      id="dataCadastro"
                      name="dataCadastro"
                      value={dayjs(valores.dataCadastro)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disabled
                      label="Ultima Modificação"
                      id="dataUltimaModificacao"
                      name="dataUltimaModificacao"
                      value={dayjs(valores.dataUltimaModificacao)}
                      format="DD/MM/YYYY"
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <TextField
                    disabled
                    id="telefone"
                    name="telefone"
                    label="Telefone"
                    placeholder="Telefone"
                    value={formatTelefone(valores.telefone) || ""}
                    type="text"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Card>

          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <Typography
                  variant={"h6"}
                  sx={{ mb: "8px" }}
                >
                  Motivo da Pendência
                </Typography>
              </Grid>
            </Grid>

            {/* Checkboxes Cadastro Novo/Alterado e Botões*/}
            <Grid
              container
              spacing={1}
              pb={1}
              alignContent="center"
            >
              <Grid
                item
                xs={12}
                sm={4.5}
              >
                <Grid
                  item
                  xs={12}
                >
                  <Checkbox
                    id="novoCadastro"
                    name="novoCadastro"
                    style={{ color: theme.palette.text.disabled }}
                    checked={valores.situacaoCadastro == "novo"}
                    disabled
                  />
                  Novo cadastro
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Checkbox
                    id="cadastroAlterado"
                    name="cadastroAlterado"
                    style={{ color: theme.palette.text.disabled }}
                    checked={valores.situacaoCadastro == "alterado"}
                    disabled
                  />
                  Cadastro Alterado
                </Grid>
              </Grid>
              <Grid
                item
                container
                spacing={1}
                xs={12}
                sm={7.5}
                justifyContent={smDown ? "flex-start" : "flex-end"}
                alignContent="center"
              >
                {valores.situacaoCadastro == "alterado" && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      href={"/parceiro/alteracoes/visualizar/" + id}
                    >
                      <Typography
                        variant={"BUTTON TEXT"}
                        sx={{
                          whiteSpace: "wrap",
                          minWidth: "auto",
                        }}
                      >
                        VISUALIZAR ALTERAÇÕES
                      </Typography>
                    </Button>
                  </Grid>
                )}
                {valores.empresaComplementar && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      href={"/parceiro/empresa-complementar/visualizar/" + id}
                    >
                      <Typography
                        variant={"BUTTON TEXT"}
                        sx={{
                          whiteSpace: "wrap",
                          minWidth: "auto",
                        }}
                      >
                        VISUALIZAR INFORMAÇÕES COMPLEMENTARES
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {/* Altere o Status do Parceiro e Botão */}
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
                sm={7}
                md={8}
              >
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: "Status é obrigatório" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth>
                      <InputLabel id="label-status">Status do Parceiro</InputLabel>
                      <Select
                        id="status"
                        labelId="label-status"
                        value={value ?? ""}
                        onChange={onChange}
                        error={!!errors.status}
                      >
                        <MenuItem
                          value={""}
                          disabled
                        >
                          Altere o Status do Parceiro
                        </MenuItem>
                        <MenuItem value="Reprovado">Reprovar</MenuItem>
                        <MenuItem value="Aprovado">Aprovar</MenuItem>
                      </Select>
                      {errors.status && <FormHelperText error>{errors.status.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4}
                justifyContent={smDown ? "flex-start" : "flex-end"}
                alignContent="center"
              >
                <Button
                  variant="outlined"
                  onClick={() => setInformacoesModal(true)}
                >
                  <Typography
                    variant={"BUTTON TEXT"}
                    sx={{
                      whiteSpace: "wrap",
                      minWidth: "auto",
                    }}
                  >
                    VISUALIZAR INFORMAÇÕES DO PARCEIRO
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            {/* Motivo */}
            <Grid
              container
              mt={2}
            >
              <Grid
                item
                xs={12}
              >
                <Controller
                  name="motivo"
                  control={control}
                  rules={{ required: "Motivo é obrigatório" }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="motivo"
                      label="Motivo"
                      placeholder="Motivo"
                      value={value}
                      onChange={onChange}
                      error={!!errors.motivo}
                      helperText={errors.motivo?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>

          {/* Oferta(s) de serviço(s) selecionado(s) pelo parceiro */}
          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
              >
                <Typography variant="subtitle2">Oferta(s) de serviço(s) selecionado(s) pelo parceiro</Typography>
              </Grid>

              <Grid
                container
                spacing={1}
                mt={1}
              >
                {valores.tipoDeServico.map((valor, index) => (
                  <Grid
                    key={valor + "_grid" + index}
                    item
                  >
                    <Button
                      id={valor}
                      name={valor}
                      variant="outlined"
                      disabled
                    >
                      <Typography
                        key={valor + "_texto"}
                        variant={"caption"}
                      >
                        {constantsService.getTipoDeServicoByCode(valor)}
                      </Typography>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Card>

          <Card
            color="#ffffff"
            sx={{
              borderRadius: "8px",
              padding: "16px",
              mt: "24px",
            }}
          >
            {/* Anexar Documentos */}
            <Grid
              container
              spacing={1}
            >
              <Grid
                item
                xs={12}
              >
                <Typography variant="subtitle2">Anexar Documentos</Typography>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Controller
                  name="arquivos"
                  control={control}
                  render={() => (
                    <FileUpload
                      initialValues={localFiles}
                      onFieldsChange={fields => handleFiles(fields, setValue)}
                      fieldLabel={"Anexar Documentos"}
                      // TODO: adicionar erro
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Card>

          {/* Botoes */}
          <Grid
            container
            spacing={1}
            justifyContent="flex-end"
          >
            <Grid
              item
              xs={12}
            >
              <Button
                variant="outlined"
                href="/parceiro/listar/pendentes"
                fullWidth
              >
                <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                variant="contained"
                disable={isSubmitting}
                loading={isSubmitting}
                fullWidth
                sx={{ gap: "8px" }}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      </form>

      <InformacoesParceiroModal
        showModal={informacoesModal}
        handleClose={() => setInformacoesModal(false)}
        parceiro={valores}
      />
    </>
  );
};

AdminAnalisarParceiroPendente.propTypes = {
  valores: PropTypes.object,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default AdminAnalisarParceiroPendente;
