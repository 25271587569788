import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { Container } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import ErroCarregamentoModal from "../../components/errors/ErrorModal";
import FormInscricaoParceiro from "../../components/formularios/empresaParceira/FormInscricaoParceiro";
import LoadingComponent from "../../components/loading/LoadingComponent";
import ConfirmacaoModal from "../../components/modals/ConfirmacaoModal";
import ErroModal from "../../components/modals/ErroModal";
import TitlePage from "../../components/title-page/TitlePage";
import { useResponsive } from "../../hooks/use-responsive";
import parceiroService from "../../services/parceiro.service";
import { transformaParaBackend } from "../../utils/parceiroEmpresaUtils";

const EditarParceiro = () => {
  const [loading, setLoading] = useState(false);
  const [confirmacaoModal, setConfirmacaoModal] = useState(false);
  const [erroModal, setErroModal] = useState(false);

  let { id } = useParams();
  // TODO remover esse default
  id = id || "1";

  const conteudoConfirmacaoModal = (
    <>
      <center>
        {
          "Atualização realizada com sucesso e enviada para aprovação da Secretaria de Inclusão Socioeconômica do Ministério do  Desenvolvimento e Assistência Social, Família e Combate à Fome."
        }
      </center>
      <center>
        {"Assim que sua atualização for validada, você será informado da aprovação por e-mail e poderá continuar a oferta de serviços."}
      </center>
    </>
  );

  const {
    isPending,
    isError,
    data: initialData,
    error,
  } = useQuery({
    queryKey: ["empresaParceira", id],
    queryFn: ({ queryKey }) => {
      const [, id] = queryKey;
      return parceiroService.getEmpresaData(id);
    },
    enabled: !!id,
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setLoading(true);

    if (
      values.checkVagaEmprego ||
      values.checkVagaEstagio ||
      values.checkVagaJovem ||
      values.checkCursos ||
      values.checkFinanceiro ||
      values.checkMobilidadePublico ||
      values.checkMobilidadeParceiro
    ) {
      const editParceiro = transformaParaBackend(values);

      return parceiroService
        .atualizaEmpresaParceira(id, editParceiro)
        .then(mensagem => {
          console.log("Sucesso:", mensagem);
          setConfirmacaoModal(true);
        })
        .catch(erro => {
          console.error("Erro ao atualizar parceiro", erro);
          setErroModal(true);
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    } else {
      setSubmitting(false);
      setLoading(false);
      console.error("Há campos em branco");
    }
  };

  if (isError) {
    console.log("Erro de carregamento:", error);
  }

  const lgUp = useResponsive("up", "lg");

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          { href: "", text: "Rede" },
          { href: "/parceiro/listar-parceiros", text: "Listar Parceiros" },
        ]}
        currentPage="Editar Parceiro"
      />

      <TitlePage title={"Editar Parceiro"} />

      {isPending || loading ? (
        <div
          style={{
            position: "absolute",
            top: lgUp ? "80px" : "64px",
            left: "0",
            marginLeft: lgUp ? "235px" : "0",
            width: lgUp ? "calc(100% - 235px)" : "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingComponent
            isLoading={isPending}
            fullScreen={false}
          />
        </div>
      ) : (
        <FormInscricaoParceiro
          initialValues={initialData}
          handleSubmit={handleSubmit}
        />
      )}

      <ConfirmacaoModal
        showModal={confirmacaoModal}
        handleClose={() => setConfirmacaoModal(false)}
        conteudo={conteudoConfirmacaoModal}
      />
      <ErroModal
        showModal={erroModal}
        handleClose={() => setErroModal(false)}
      />

      {isError && <ErroCarregamentoModal isShow={true} />}
    </Container>
  );
};

export default EditarParceiro;
