import React from "react";

import { Container } from "@mui/material";

import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import VagaTrabalho from "../../components/formularios/VagaTrabalho";
import TitlePage from "../../components/title-page/TitlePage";

const PageNovaVagaTrabalho = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[
          {
            href: "/",
            text: "Página Inicial",
          },
          {
            href: "/parceiro",
            text: "Parceiro",
          },
        ]}
        currentPage={"Cadastrar Vaga de Trabalho"}
      />

      <TitlePage title={"Dados da vaga"} />

      <VagaTrabalho />
    </Container>
  );
};

export default PageNovaVagaTrabalho;
