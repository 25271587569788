import React, { useCallback, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, Button, FormControlLabel, FormGroup, Grid, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import { useResponsive } from "../../hooks/use-responsive";
import { SelectAtuacaoParceiro } from "./fields/SelectAtuacaoParceiro.js";

const FormExigenciaVagaTrabalho = ({ onFieldsChange, label, placeholder, list, visualizacao = false, initialValue = [] }) => {
  const theme = useTheme();

  const defaultFields = (initialValue ?? []).map((valueSet, index) => ({
    id: Date.now() + index,
    input1: valueSet[0] || "",
    input2: valueSet[1] || "",
    input3: valueSet[2] || "",
  }));

  if (defaultFields.length === 0) {
    defaultFields.push({
      id: Date.now(),
      input1: "",
      input2: "",
      input3: "",
    });
  }

  const [fields, setFields] = useState(defaultFields);

  const handleChange = useCallback(
    (id, value, field) => {
      setFields(prevFields => {
        const updatedFields = prevFields.map(f => (f.id === id ? { ...f, [field]: value } : f));
        onFieldsChange(updatedFields);
        return updatedFields;
      });
    },
    [onFieldsChange],
  );

  const handleAddField = () => {
    setFields(prevFields => {
      const newId = prevFields.length > 0 ? prevFields[prevFields.length - 1].id + 1 : 1;
      return [...prevFields, { id: newId, input1: "", input2: "", input3: "" }];
    });
  };

  const handleRemoveField = id => {
    if (fields.length > 1) {
      setFields(prevFields => {
        const updatedFields = prevFields.filter(f => f.id !== id);
        onFieldsChange(updatedFields);
        return updatedFields;
      });
    }
  };

  const isMd = useResponsive("up", "sm");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, mt: 1 }}>
      <Grid
        container
        spacing={2}
        px={2}
        mt={1}
      >
        {fields.map((field, index) => (
          <React.Fragment key={field.id}>
            {
              <Grid
                container
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={8}
                >
                  <FormGroup>
                    <SelectAtuacaoParceiro
                      idSelect={"id_" + label + "_0"}
                      nameSelect={"name_" + label + "_0"}
                      handleSelect={e => handleChange(field.id, e.target.value, "input1")}
                      list={list}
                      item={field.input1.toString()}
                      label={label}
                      placeholder={placeholder}
                      visualizacao={visualizacao}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <FormGroup>
                    <TextField
                      label={"Experiência anterior"}
                      id={"id_" + field.id + "_0"}
                      value={field.input2}
                      type="text"
                      onChange={e => handleChange(field.id, e.target.value, "input2")}
                      disabled={visualizacao}
                    />
                  </FormGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Stack
                    spacing={1}
                    mt={1}
                  >
                    <FormGroup>
                      <RadioGroup
                        name="situacao"
                        value={field.input3}
                        onChange={e => handleChange(field.id, e.target.value, "input3")}
                      >
                        <FormControlLabel
                          value="Desejado"
                          control={<Radio style={{ color: theme.palette.primary.main }} />}
                          label="Desejado"
                          disabled={visualizacao}
                        />
                        <FormControlLabel
                          value="Exigido"
                          control={<Radio style={{ color: theme.palette.primary.main }} />}
                          label="Exigido"
                          disabled={visualizacao}
                        />
                      </RadioGroup>
                    </FormGroup>
                  </Stack>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <div style={{ display: `flex`, flexDirection: `row`, justifyContent: isMd ? `end` : "flex-start", gap: `16px` }}>
                    <Button
                      size="large"
                      type="button"
                      variant="outlined"
                      onClick={() => handleRemoveField(field.id)}
                      disabled={fields.length === 1}
                    >
                      <DeleteOutlineIcon />
                    </Button>
                    <Button
                      size="large"
                      type="button"
                      startIcon={<AddCircleOutlineIcon />}
                      variant="outlined"
                      onClick={handleAddField}
                      disabled={field.input1.length < 1}
                    >
                      ADICIONAR ÁREA
                    </Button>
                  </div>
                </Grid>
              </Grid>
            }
            {index < fields.length - 1 && (
              <div style={{ width: "100%", borderTop: "1px solid #ccc", marginTop: "16px", marginBottom: "16px" }} />
            )}
          </React.Fragment>
        ))}
      </Grid>
    </Box>
  );
};

FormExigenciaVagaTrabalho.propTypes = {
  onFieldsChange: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  list: PropTypes.array.isRequired,
  visualizacao: PropTypes.bool,
};

export default FormExigenciaVagaTrabalho;
