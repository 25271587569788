/* eslint-disable no-unused-vars */
/* eslint-disable simple-import-sort/imports */
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Chip, FormControl, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import { st_pronatec, tp_curso, tp_escolaridade, tp_modalidade, tp_turno } from "../../services/constants.js";
import optionsService from "../../services/options.service";
import FileUpload from "../inputs/fileUpload.js";
import { FormFieldsGrid } from "./DynamicForm.js";
import FormAddressSchool from "./FormAddressSchool.js";

const valoresIniciais = {
  cnpjOfertante: "",
  nomeOfertante: "",
  cargaHoraria: "",
  turno: "",
  nomeDoCurso: "",
  escolaridade: "",
  tipoDeCurso: "",
  pronatec: "",
  pre_requisitos: [],
  inicio_matricula: null,
  fim_matricula: null,
  idade_min: "",
  idade_max: "",
  modalidade: "",
  trilhas: "",
  link: "",

  inicio_curso: null,
  fim_curso: null,
  cep: "",
  uf: "",
  endereco: "",
  numero: "",
  complemento: "",
  bairro: "",
  municipio: "",
  telefone: "",
  vagas: "",
  abrangencia: "",
  uf_abrangencia: "",
  municipio_abrangencia: "",

  ementa: "",
  descricao: "",
  vigencia: "",
  ultima_modificacao: "",
  situacao: "",
  motivo_situacao: "",
};

const FormCurso = ({ valores = valoresIniciais, visualizacao = false, onSubmit }) => {
  const navigate = useNavigate();
  const formRef = useRef();
  //const [values, setInitialValues] = valores ? useState(valores) : useState(valoresIniciais);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: valores });

  const [localFiles, setLocalFiles] = useState(valores.anexo);
  const handleFiles = newFiles => {
    setLocalFiles(newFiles);
    setValue("anexo", newFiles);
  };

  const listaPreRequisitos = [
    "Ensino Fundamental",
    "Ensino Médio",
    "Ensino Superior",
    "Conhecimento em Informática",
    "Conhecimento em Matemática",
    "Conhecimento em Português",
    "Conhecimento em Inglês",
    "Conhecimento em Espanhol",
    "Conhecimento em Francês",
    "Conhecimento em Alemão",
    "Conhecimento em Chinês",
    "Ciências Humanas",
  ];

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const fetchMunicipiosByUf = async (ufSigla, setCidadesFunction) => {
    if (ufSigla === undefined || ufSigla == "") {
      return;
    }
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error, " - ufSigla => ", ufSigla);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;

    setValue(name, value);
    setValue("municipio", "");
    fetchMunicipiosByUf(value, setMunicipios);
  };

  const [ufsAbrangencia, setUfsAbrangencia] = useState([]);
  const [municipiosAbrangencia, setMunicipiosAbrangencia] = useState([]);

  const fetchMunicipiosAbrangenciaByUf = async (ufSigla, setCidadesFunction) => {
    if (ufSigla === undefined || ufSigla == "") {
      return;
    }
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error, " - ufSigla => ", ufSigla);
    }
  };

  const handleSelectUfAbrangencia = event => {
    const { name, value } = event.target;

    setValue(name, value);
    setValue("municipioAbrangencia", "");
    fetchMunicipiosAbrangenciaByUf(value, setMunicipiosAbrangencia);
  };

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
          setUfsAbrangencia(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fieldsPrincipal = [
    // CNPJ e Ofertante
    {
      name: "cnpjOfertante",
      label: "CNPJ ofertante",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>CNPJ ofertante</InputLabel>
            <Select
              id="cnpjOfertante"
              name="cnpjOfertante"
              value={value ?? ""}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um CNPJ
              </MenuItem>
              <MenuItem value={0}>000.000.000/0000-00</MenuItem>
              <MenuItem value={1}>111.111.111/1111-11</MenuItem>
              <MenuItem value={2}>222.222.222/2222-22</MenuItem>
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "nomeOfertante",
      label: "Nome do ofertante",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="nomeOfertante"
          name="nomeOfertante"
          label="Nome do ofertante"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    // Carga horária e turno
    {
      name: "cargaHoraria",
      label: "*Carga horária",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="cargaHoraria"
          name="cargaHoraria"
          label="*Carga horária"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "turno",
      label: "Turno",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Turno</InputLabel>
            <Select
              id="turno"
              name="turno"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um Turno
              </MenuItem>

              {Object.keys(tp_turno).map(turno => (
                <MenuItem
                  key={turno}
                  value={turno}
                >
                  {tp_turno[turno]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Nome do curso
    {
      name: "nomeDoCurso",
      label: "Nome do curso",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="nomeDoCurso"
          name="nomeDoCurso"
          label="Nome do curso"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    // Escolaridade e tipo
    {
      name: "escolaridade",
      label: "Escolaridade",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Escolaridade exigida</InputLabel>
            <Select
              id="escolaridade"
              name="escolaridade"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma Escolaridade
              </MenuItem>

              {Object.keys(tp_escolaridade).map(escolaridade => (
                <MenuItem
                  key={escolaridade}
                  value={escolaridade}
                >
                  {tp_escolaridade[escolaridade]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "tipoDeCurso",
      label: "Tipo de Curso",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Tipo de Curso</InputLabel>
            <Select
              id="tipoDeCurso"
              name="tipoDeCurso"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione um Tipo de Curso
              </MenuItem>

              {Object.keys(tp_curso).map(curso => (
                <MenuItem
                  key={curso}
                  value={curso}
                >
                  {tp_curso[curso]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Pronatec e Pre requisitos
    {
      name: "pronatec",
      label: "Pronatec",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Pronatec</InputLabel>
            <Select
              id="pronatec"
              name="pronatec"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione PRONATEC
              </MenuItem>

              {Object.keys(st_pronatec).map(value => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {st_pronatec[value]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "pre_requisitos",
      label: "Pré-requisitos",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Pré-requisitos</InputLabel>
            <Select
              id="pre_requisitos"
              name="pre_requisitos"
              multiple
              value={value}
              onChange={onChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Chip"
                />
              }
              renderValue={selected => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={value}
                    />
                  ))}
                </Box>
              )}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione os Pré-requisitos
              </MenuItem>

              {listaPreRequisitos.map(req => (
                <MenuItem
                  key={req}
                  value={req}
                >
                  {req}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    // Inicio e Fim das Matrículas
    {
      name: "inicio_matricula",
      label: "Início matrícula",

      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="inicio_matricula"
              name="inicio_matricula"
              label="Início matrícula"
              value={dayjs(value)}
              disabled={visualizacao}
              fullWidth
              error={false}
              format="DD/MM/YYYY"
              onChange={onChange}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />
          </LocalizationProvider>
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "fim_matricula",
      label: "Fim matrícula",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="fim_matricula"
              name="fim_matricula"
              label="Fim matrícula"
              value={dayjs(value)}
              disabled={visualizacao}
              fullWidth
              format="DD/MM/YYYY"
              onChange={onChange}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />
          </LocalizationProvider>
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    // Idade mínima e máxima
    {
      name: "idade_min",
      label: "Idade mínima",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="idade_min"
          name="idade_min"
          label="Idade mínima"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "idade_max",
      label: "Idade máxima",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="idade_max"
          name="idade_max"
          label="Idade máxima"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    // Modalidade e  Trilhas
    {
      name: "modalidade",
      label: "Modalidade",
      renderComponent: ({ value, onChange }) => {
        return (
          <FormControl fullWidth>
            <InputLabel>Modalidade</InputLabel>
            <Select
              id="modalidade"
              name="modalidade"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
            >
              <MenuItem
                key={""}
                value={""}
                disabled
              >
                Selecione uma Modalidade
              </MenuItem>

              {Object.keys(tp_modalidade).map(modalidade => (
                <MenuItem
                  key={modalidade}
                  value={modalidade}
                >
                  {tp_modalidade[modalidade]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
      size: { xs: 12, sm: 6 },
    },
    {
      name: "trilhas",
      label: "Trilhas",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="trilhas"
          name="trilhas"
          label="Trilhas"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    // Inicio e Fim do Curso
    {
      name: "inicio_curso",
      label: "Início do curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="inicio_curso"
              name="inicio_curso"
              label="Início do curso"
              value={dayjs(value)}
              disabled={visualizacao}
              fullWidth
              format="DD/MM/YYYY"
              onChange={onChange}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />
          </LocalizationProvider>
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "fim_curso",
      label: "Fim do curso",
      renderComponent: ({ value, onChange }) => (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              id="fim_curso"
              name="fim_curso"
              label="Fim do curso"
              value={dayjs(value)}
              disabled={visualizacao}
              fullWidth
              format="DD/MM/YYYY"
              onChange={onChange}
              slotProps={{
                textField: {
                  error: false,
                },
              }}
            />
          </LocalizationProvider>
        </FormGroup>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    //Link Externo
    {
      name: "link",
      label: "Link Externo",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="link"
          name="link"
          label="Link Externo"
          value={value}
          disabled={visualizacao}
          onChange={onChange}
          fullWidth
        />
      ),
      size: { xs: 12, md: 6 },
    },
  ];
  const fieldsOpcional = [
    // Informações adicionais
    {
      name: "info_adicional",
      label: "info_adicional",
      renderComponent: () => (
        <Typography
          variant="h6"
          style={{ fontFamily: "Rawline Bold" }}
        >
          Informações adicionais para modalidade presencial ou semipresencial
        </Typography>
      ),
      size: { xs: 12 },
    },
    {
      name: "FormAddressSchool",
      label: "FormAddressSchool",
      validation: { required: "Necessário preencher o campo!" },
      renderComponent: ({ value, onChange }) => {
        return (
          <FormAddressSchool
            onChange={onChange}
            values={value}
          />
        );
      },
      size: { xs: 12, sm: 12, md: 12, lg: 12 },
    },
  ];
  const fieldsFinal = [
    // Ementa e breve descrição
    {
      name: "ementa",
      label: "Ementa",
      renderComponent: ({ value, onChange }) => (
        <TextField
          multiline
          rows={3}
          id="ementa"
          name="ementa"
          label="Ementa"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "descricao",
      label: "Descrição",
      renderComponent: ({ value, onChange }) => (
        <TextField
          multiline
          rows={3}
          id="descricao"
          name="descricao"
          label="Breve Descrição"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12 },
    },
    // Anexar Imagem
    {
      name: "anexarImagem",
      label: "Anexar Imagem",
      renderComponent: () => {
        if (!visualizacao) {
          return (
            <>
              <FileUpload
                initialValues={localFiles}
                maxFiles={1}
                onFieldsChange={fields => handleFiles(fields)}
                // showFiles={true}
                // TODO: adicionar erro
              />
            </>
          );
        }
      },
      size: { xs: 12 },
    },
    // Vigência, última modificação e situação
    {
      name: "vigencia",
      label: "Vigência",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="vigencia"
              name="vigencia"
              label="Vigência"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    {
      name: "ultima_modificacao",
      label: "Última modificação",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="ultima_modificacao"
              name="ultima_modificacao"
              label="Última modificação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="situacao"
              name="situacao"
              label="Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          );
        }
      },
      size: { xs: 12, sm: 4 },
    },
    // Motivo situação
    {
      name: "motivo_situacao",
      label: "Motivo da Situação",
      renderComponent: ({ value, onChange }) => {
        if (visualizacao) {
          return (
            <TextField
              id="motivo_situacao"
              name="motivo_situacao"
              label="Motivo da Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              multiline
              fullWidth
            />
          );
        }
      },
      size: { xs: 12 },
    },
  ];

  // const handleFormChange = updatedField => {
  //   setInitialValues(prevValues => ({
  //     ...prevValues,
  //     ...updatedField,
  //   }));
  // };

  return (
    <>
      {/* <DynamicForm
        ref={formRef}
        fieldsPrincipal={fieldsPrincipal}
        fieldsOpcional={fieldsOpcional}
        fieldsFinal={fieldsFinal}
        visualizacao={visualizacao}
        onSubmit={valores => {
          handleSubmit(valores);
        }}
        initialValues={values}
        onChange={handleFormChange}
      /> */}
      <form
        //ref={ref}
        onSubmit={handleSubmit(onSubmit)}
        //onChange={onChange}
      >
        <Card
          sx={{
            padding: 2,
            mt: 2,
            borderRadius: 2,
          }}
        >
          {!visualizacao && (
            <Grid
              item
              xs={12}
            >
              <Typography
                variant="h6"
                style={{ fontFamily: "Rawline Bold" }}
                sx={{ mb: 2 }}
              >
                Dados do Curso
              </Typography>
            </Grid>
          )}
          <FormFieldsGrid
            fields={fieldsPrincipal}
            control={control}
            errors={errors}
          />
        </Card>
        <Card
          sx={{
            padding: 2,
            mt: 2,
            borderRadius: 2,
          }}
        >
          <FormFieldsGrid
            fields={fieldsOpcional}
            control={control}
            errors={errors}
          />
        </Card>
        <Card
          sx={{
            padding: 2,
            mt: 2,
            borderRadius: 2,
          }}
        >
          <FormFieldsGrid
            fields={fieldsFinal}
            control={control}
            errors={errors}
          />
        </Card>
        <Grid
          container
          spacing={2}
          mt={2}
          justifyContent="flex-end"
        >
          {!visualizacao && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => navigate("/parceiro/cursos/listar")}
                fullWidth
              >
                Cancelar
              </Button>
            </Grid>
          )}
          {!visualizacao && (
            <Grid item>
              <LoadingButton
                variant="contained"
                type="submit"
                sx={{ gap: "8px" }}
              >
                <SaveIcon fontSize="small" />
                <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
              </LoadingButton>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

FormCurso.propTypes = {
  valores: PropTypes.object,
  visualizacao: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default FormCurso;
