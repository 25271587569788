import React from "react";

import CircleIcon from "@mui/icons-material/Circle";
import { Box, Card, Container, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import InformationBox from "../../components/boxes/InformationBox";
import CardBreadcrumb from "../../components/cards/CardBreadcrumb";
import DefaultTable from "../../components/table/DefaultTable";
import TitlePage from "../../components/title-page/TitlePage";
import { useAuth } from "../../contexts/AuthContext";

const PageUsuariosParceiro = () => {
  const { user } = useAuth();

  const rows = [
    {
      nome: user.no_usuario,
      email: user.ds_email,
    },
  ];

  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 0 }}
    >
      <CardBreadcrumb
        links={[{ href: "/parceiro/", text: "Parceiro" }]}
        currentPage="Usuário do Parceiro"
      />

      <TitlePage title="Usuários do Parceiro" />

      {/* Aviso na página */}
      <Grid container>
        <Grid
          item
          align="left"
          xs={12}
          mb={2}
        >
          <InformationBox
            title=""
            message="Usuários listados em amarelo ainda não acessaram o sistema. Após o primeiro acesso o vínculo é confirmado e seu nome aparecerá
            em verde."
          />
        </Grid>
      </Grid>

      <Stack spacing={1}>
        <Card
          color="#ffffff"
          sx={{
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <Typography
            variant="h4"
            mt={3}
            mb={3}
            sx={{ fontFamily: "Rawline Bold" }}
            style={{ transition: "1s" }}
          >
            Lista de Usuários Representantes do Parceiro
          </Typography>

          {/* Cores */}
          <Box>
            <Grid
              container
              mb={2}
            >
              <Grid
                item
                xs={12}
                sm={4}
              >
                <CircleIcon sx={{ color: "#2670E8", fontSize: "small", marginRight: "5px" }} />
                Usuário logado
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <CircleIcon sx={{ color: "#FFCF00", fontSize: "small", marginRight: "5px" }} />
                Usuário que nunca acessou o parceiro
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <CircleIcon sx={{ color: "#00CA45", fontSize: "small", marginRight: "5px" }} />
                Usuário que já acessou o parceiro
              </Grid>
            </Grid>
          </Box>

          {/* Tabela */}
          <Box sx={{ flexGrow: 1, mt: "40px" }}>
            <DefaultTable
              columns={[
                { field: "nome", headerName: "Nome Completo" },
                { field: "email", headerName: "E-mail" },
              ]}
              rows={rows}
              actionButtons={() => [
                {
                  title: "Remover",
                  icon: "delete",
                },
              ]}
              mostrarCirculosColoridos={true}
              listaCirculosColoridos={["#2670E8", "#FFCF00", "#00CA45"]}
            />
          </Box>
        </Card>
      </Stack>
    </Container>
  );
};

export default PageUsuariosParceiro;
