import constantsService from "../services/constants.service";

/**
 * Transforma os dados de servicos de uma empresa do parceiro em um objeto de dados unico.
 * @param {Object} tipoDeServico - Os dados de servicos de uma empresa do parceiro.
 * @returns {Object} Um objeto de dados unico com os dados de servicos de uma empresa.
 */
const transformarServicos = tipoDeServico => {
  return {
    checkVagaEmprego: !!tipoDeServico.VEP,
    checkVagaEstagio: !!tipoDeServico.VET,
    checkVagaJovem: !!tipoDeServico.VJA,
    checkCursos: !!tipoDeServico.CUR,
    checkFinanceiro: !!tipoDeServico.FPG,
    checkMobilidadePublico: !!tipoDeServico.MPU,
    checkMobilidadeParceiro: !!tipoDeServico.MPA,
  };
};

/**
 * Transforma os dados de uma empresa do parceiro em um objeto de dados unico.
 *
 * @param {Object} dadosRecebidos - Os dados de uma empresa do parceiro.
 * @return {Object} Um objeto de dados unico com os dados de uma empresa.
 */
export const transformarDadosUnicaEmpresa = dadosRecebidos => {
  // Dados recebidos vem nesse formato
  // dadosRecebidos.dados
  // dadosRecebidos.ponto_focal
  // dadosRecebidos.representante
  // dadosRecebidos.servico_ofertado

  // Padronização Status e Situação
  let situacaoStatus = constantsService.getStatusByCode(dadosRecebidos.dados.tp_situacao_registro || "");
  if (situacaoStatus === "Pendente ADM") {
    situacaoStatus = "Pendente";
  }

  const dados = {
    situacao: situacaoStatus,
    status: situacaoStatus,
    email: dadosRecebidos.dados.email,
    cnpj: dadosRecebidos.dados.cnpj,
    razaoSocial: dadosRecebidos.dados.razao_social,
    nomeFantasia: dadosRecebidos.dados.nome_fantasia,
    cep: dadosRecebidos.dados.cep,
    endereco: dadosRecebidos.dados.logradouro,
    numero: dadosRecebidos.dados.numero,
    bairro: dadosRecebidos.dados.bairro,
    complemento: dadosRecebidos.dados.complemento,
    uf: dadosRecebidos.dados.uf,
    cidade: dadosRecebidos.dados.cidade,
    tipo: dadosRecebidos.dados.tipo,
    porte: dadosRecebidos.dados.porte,
    telefone: dadosRecebidos.dados.telefone,
    areaAtuacao: dadosRecebidos.dados.area_atuacao.toString(),
    naturezaJuridica: dadosRecebidos.dados.nome_natureza_juridica.toString(),
    naturezaJuridicaCodigo: dadosRecebidos.dados.natureza_juridica.toString(),
    site: dadosRecebidos.dados.site,
    redesSociais: dadosRecebidos.dados.redes_sociais,
    empresaComplementar: dadosRecebidos.dados.id_empresa_complementar,
    ids_anexos: dadosRecebidos.dados.ids_anexos,
  };

  const dadosPontoFocal = {
    nomePontoFocal: dadosRecebidos.ponto_focal.nome,
    emailPontoFocal: dadosRecebidos.ponto_focal.email,
    telefonePontoFocal: dadosRecebidos.ponto_focal.telefone,
  };

  // Padronização Representante e Responsável
  const nomeRepresentanteResponsavel = dadosRecebidos.representante.nome;
  const cpfRepresentanteResponsavel = dadosRecebidos.representante.cpf;
  const telefoneRepresentanteResponsavel = dadosRecebidos.representante.telefone;
  const ufRepresentanteResponsavel = dadosRecebidos.representante.uf;
  const cidadeRepresentanteResponsavel = dadosRecebidos.representante.cidade;

  const dadosRepresentante = {
    nomeRepresentante: nomeRepresentanteResponsavel,
    cpfRepresentante: cpfRepresentanteResponsavel,
    telefoneRepresentante: telefoneRepresentanteResponsavel,
    ufRepresentante: ufRepresentanteResponsavel,
    cidadeRepresentante: cidadeRepresentanteResponsavel,
    nomeResponsavel: nomeRepresentanteResponsavel,
    cpfResponsavel: cpfRepresentanteResponsavel,
    telefoneResponsavel: telefoneRepresentanteResponsavel,
    ufResponsavel: ufRepresentanteResponsavel,
    cidadeResponsavel: cidadeRepresentanteResponsavel,
  };

  const tipoDeServico = transformarServicos(dadosRecebidos.servico_ofertado);

  // Os dados de ciente de normas e gratuito são usados ao editar parceiro
  const checksForm = {
    toggleCienteNormas: false,
    toggleCienteGratuito: false,
  };
  return {
    ...dados,
    ...dadosPontoFocal,
    ...dadosRepresentante,
    ...tipoDeServico,
    tipoDeServico: dadosRecebidos.servico_ofertado,
    ...checksForm,
  };
};

/**
 * Transforma os dados de uma empresa do parceiro em um objeto de dados unico.
 *
 * @param {Object} dadosRecebidos - Os dados de uma empresa do parceiro.
 * @return {Object} Um objeto de dados unico com os dados de uma empresa.
 *
 */
export const transformarDadosMaisEmpresas = dadosRecebidos => {
  // console.log("transformarDadosMaisEmpresas", dadosRecebidos);

  const dados = {
    id: dadosRecebidos.pk_empresa_parceira,
    habilitacao: "Parceiro",
    // Datas
    dataCadastro: new Date(dadosRecebidos.dh_criacao).toISOString(),
    dataUltimaModificacao: new Date(dadosRecebidos.dh_atualizacao).toISOString(),
  };

  // Enviando os dados ajustados e os detalhes que já estavam ajustados
  return { ...dados, ...dadosRecebidos.detalhes };
};

export const transformEmpresasComDetalhes = empresasComDetalhes => {
  return empresasComDetalhes.map(transformarDadosMaisEmpresas);
};

export const transformaParaBackend = values => {
  const newValues = {
    dados: {
      email: values.email,
      cnpj: values.cnpj.replace(/[^\d]/g, ""),
      razao_social: values.razaoSocial,
      nome_fantasia: values.nomeFantasia,
      cep: values.cep.replace(/[^\d]/g, ""),
      logradouro: values.endereco,
      numero: values.numero || "0",
      bairro: values.bairro,
      uf: values.uf,
      cidade: values.cidade,
      telefone: values.telefone.replace(/[^\d]/g, ""),
      tipo: values.tipo,
      porte: values.porte,
      area_atuacao: values.areaAtuacao,
      natureza_juridica: parseInt(values.naturezaJuridicaCodigo, 10),
      nome_natureza_juridica: values.naturezaJuridica,
      redes_sociais: values.redesSociais || [],
      // Site e complemento são opcionais, aí não devem ser mandados no json
      ...(values.site && { site: values.site }),
      ...(values.complemento && { complemento: values.complemento }),
    },
    representante: {
      nome: values.nomeRepresentante,
      cpf: values.cpfRepresentante.replace(/[^\d]/g, ""),
      telefone: values.telefoneRepresentante.replace(/[^\d]/g, ""),
      uf: values.ufRepresentante,
      cidade: values.cidadeRepresentante,
    },
    ponto_focal: {
      nome: values.nomePontoFocal,
      email: values.emailPontoFocal,
      telefone: values.telefonePontoFocal.replace(/[^\d]/g, ""),
    },
    servico_ofertado: {
      VEP: values.checkVagaEmprego,
      VET: values.checkVagaEstagio,
      VJA: values.checkVagaJovem,
      CUR: values.checkCursos,
      FPG: values.checkFinanceiro,
      MPU: values.checkMobilidadePublico,
      MPA: values.checkMobilidadeParceiro,
    },
  };

  // console.log("Array de redes sociais -> ", newValues.dados.redes_sociais[0].length);

  if (newValues.dados.redes_sociais[0].length < 1) {
    //Caso não envie nenhuma rede social deve remover do objeto
    delete newValues.dados.redes_sociais;
  }

  return newValues;
};
