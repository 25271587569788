import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PropTypes from "prop-types";

import { tp_escolaridadeVagaTrabalho, tp_turno, tp_vaga, validadeVaga } from "../../services/constants";
import optionsService from "../../services/options.service";
import DynamicForm from "./DynamicForm";
import { SelectCidade } from "./fields/SelectCidade";
import { SelectUF } from "./fields/SelectUF";
import FormExigenciaVagaTrabalho from "./FormExigenciaVagaTrabalho";

const valoresIniciais = {
  tipoVaga: "",
  cnpj: "",
  nomeOfertante: "",
  funcao: "",
  quantidadeVagas: "",
  validadeAnuncio: "",
  escolaridadeRequerida: "",
  escolaridadeMinima: false,
  vagaPCD: false,
  localLogradouro: false,
  salario: "",
  turno: "",
  descricao: "",
  uf: "",
  municipio: "",
  cep: "",
  endereco: "",
  numero: "",
  complemento: "",
  referencia: "",
  bairro: "",
  auxilioAlimentacao: false,
  auxilioTransporte: false,
  planoSaude: false,
  outros: false,
  detalheAuxilioAlimentacao: "",
  detalheAuxilioTransporte: "",
  detalhePlanoSaude: "",
  detalheOutros: "",
  areaAtuacaoProfissional: [],
  areaAtuacaoEstudante: [],
  cursoComplementar: [],
  cursoTecnico: [],
  cursoSuperior: [],
};

const VagaTrabalho = ({ initialValues = valoresIniciais, visualizacao, isAdmin = false }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const formRef = useRef();

  // TODO: Usar valores do banco
  const listaAreaAtuacao = {
    profissional: [
      {
        id: "1",
        descricao: "Tecnologia da Informação",
      },
      {
        id: "2",
        descricao: "Saúde",
      },
      {
        id: "3",
        descricao: "Educação",
      },
      {
        id: "5",
        descricao: "Engenharia",
      },
    ],
    estudante: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Agricultura" },
      { id: "3", descricao: "Artes" },
      { id: "5", descricao: "Ciências" },
      { id: "6", descricao: "Comunicação" },
      { id: "7", descricao: "Direito" },
      { id: "8", descricao: "Educação" },
      { id: "9", descricao: "Engenharia" },
      { id: "10", descricao: "Informática" },
      { id: "11", descricao: "Saúde" },
    ],
  };

  // TODO: Usar valores do banco
  const listaCursos = {
    complementar: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    tecnico: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
    superior: [
      { id: "1", descricao: "Administração" },
      { id: "2", descricao: "Engenharia" },
      { id: "3", descricao: "Informática" },
      { id: "5", descricao: "Saúde" },
    ],
  };

  const fields = [
    {
      name: "tipoVaga",
      label: "Tipo de Vaga",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel>Tipo de vaga</InputLabel>
          <Select
            id="tipoVaga"
            name="tipoVaga"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione um Tipo
            </MenuItem>
            {Object.keys(tp_vaga).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_vaga[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "cnpj",
      label: "CNPJ",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="cnpj"
          name="cnpj"
          label="CNPJ"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "nomeOfertante",
      label: "Nome do Ofertante",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="nomeOfertante"
          name="nomeOfertante"
          label="Nome do Ofertante"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "funcao",
      label: "Nome da Função",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="funcao"
          name="funcao"
          label="Nome da Função"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "quantidadeVagas",
      label: "Quantidade de Vagas",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="quantidadeVagas"
          name="quantidadeVagas"
          label="Quantidade de Vagas"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "validadeAnuncio",
      label: "Validade do Anúncio",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel>Validade do Anúncio</InputLabel>
          <Select
            id="validadeAnuncio"
            name="validadeAnuncio"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione a Validade
            </MenuItem>
            {Object.keys(validadeVaga).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {validadeVaga[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "escolaridadeRequerida",
      label: "Escolaridade Requerida",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel>Escolaridade Requerida</InputLabel>
          <Select
            id="escolaridadeRequerida"
            name="escolaridadeRequerida"
            label="Escolaridade Requerida"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
            fullWidth
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione uma Escolaridade
            </MenuItem>
            {Object.keys(tp_escolaridadeVagaTrabalho).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_escolaridadeVagaTrabalho[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "salario",
      label: "Salário/Bolsa Auxílio",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="salario"
          name="salario"
          label="Salário/Bolsa Auxílio"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "turno",
      label: "Turno",
      renderComponent: ({ value, onChange }) => (
        <FormControl fullWidth>
          <InputLabel>Turno</InputLabel>
          <Select
            id="turno"
            name="turno"
            value={value}
            onChange={onChange}
            disabled={visualizacao}
          >
            <MenuItem
              key={""}
              value={""}
              disabled
            >
              Selecione um Turno
            </MenuItem>
            {Object.keys(tp_turno).map(value => (
              <MenuItem
                key={value}
                value={value}
              >
                {tp_turno[value]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "situacao",
      label: "Situação",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <TextField
              id="situacao"
              name="situacao"
              label="Situação"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "dataResposta",
      label: "Data de Resposta",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <FormGroup>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data da Resposta"
                  id="dataResposta"
                  name="dataResposta"
                  value={value}
                  format="DD/MM/YYYY"
                  onChange={onChange}
                  disabled={visualizacao}
                />
              </LocalizationProvider>
            </FormGroup>
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "interessados",
      label: "Interessados",
      renderComponent: ({ value, onChange }) => (
        <>
          {isAdmin && (
            <TextField
              id="interessados"
              name="interessados"
              label="Interessados"
              value={value}
              onChange={onChange}
              disabled={visualizacao}
              fullWidth
            />
          )}
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "escolaridadeMinima",
      label: "Escolaridade mínima exigida?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              id="escolaridadeMinima"
              name="escolaridadeMinima"
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Escolaridade mínima exigida?"
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "descricao",
      label: "* Descrição/Informações Adicionais",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="descricao"
          name="descricao"
          label="* Descrição/Informações Adicionais"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          rows={4}
          fullWidth
          multiline
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "vagaPCD",
      label: "Essa vaga é direcionada para pessoas com deficiência?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              name="vagaPCD"
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Essa vaga é direcionada para pessoas com deficiência?"
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "localLogradouro",
      label: "O local de trabalho será o mesmo cadastrado no logradouro do parceiro?",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          control={
            <Checkbox
              name="localLogradouro"
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="O local de trabalho será o mesmo cadastrado no logradouro do parceiro?"
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "labelLogradouro",
      label: "Outro Logradouro",
      renderComponent: () => (
        <Grid
          item
          xs={12}
        >
          <Typography variant="h6">Outro Logradouro</Typography>
        </Grid>
      ),
    },
    {
      name: "uf",
      label: "* UF",
      renderComponent: ({ value }) => (
        <SelectUF
          idSelect="uf"
          nameSelect="uf"
          value={value}
          ufs={ufs}
          uf={selectedUf}
          label="* UF"
          visualizacao={visualizacao}
          handleSelectUf={event => handleSelectUf(event)}
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "municipio",
      label: "* Município",
      renderComponent: ({ value }) => (
        <>
          <SelectCidade
            idSelect="municipio"
            nameSelect="municipio"
            value={value}
            cidades={municipios}
            cidade={selectedMunicipio}
            label="* Município"
            visualizacao={visualizacao}
            handleSelectCidade={event => handleSelectMunicipio(event)}
          />
        </>
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "cep",
      label: "* CEP",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="cep"
          name="cep"
          label="* CEP"
          value={value}
          disabled={visualizacao}
          onChange={onChange}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "endereco",
      label: "* Logradouro",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="endereco"
          name="endereco"
          label="* Logradouro"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "numero",
      label: "* Número",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="numero"
          name="numero"
          label="* Número"
          type="number"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "bairro",
      label: "Bairro",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="bairro"
          name="bairro"
          label="Bairro"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 4 },
    },
    {
      name: "complemento",
      label: "Complemento",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="complemento"
          name="complemento"
          label="Complemento"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "referencia",
      label: "Referência",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="referencia"
          name="referencia"
          label="Referência"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6 },
    },
    {
      name: "labelBeneficios",
      label: "Benecícios",
      renderComponent: () => <Typography variant="h6">Benefícios</Typography>,
      size: { xs: 12 },
    },
    {
      name: "auxilioAlimentacao",
      label: "Auxílio Alimentação",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"auxilioAlimentacao"}
          name={"auxilioAlimentacao"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Auxílio Alimentação"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheAuxilioAlimentacao",
      label: "Datalhes do Auxílio Alimentação",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheAuxilioAlimentacao"
          name="detalheAuxilioAlimentacao"
          label="Detalhes do Auxílio Alimentação"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "auxilioTransporte",
      label: "Auxílio Transporte",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"auxilioTransporte"}
          name={"auxilioTransporte"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Auxílio Transporte"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheAuxilioTransporte",
      label: "Datalhes do Auxílio Transporte",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheAuxilioTransporte"
          name="detalheAuxilioTransporte"
          label="Detalhes do Auxílio Transporte"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "planoSaude",
      label: "Plano de Saúde",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"planoSaude"}
          name={"planoSaude"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Plano Saúde"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalhePlanoSaude",
      label: "Datalhes do Plano Saúde",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalhePlanoSaude"
          name="detalhePlanoSaude"
          label="Detalhes do Plano de Saúde"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "outros",
      label: "Outros",
      renderComponent: ({ value, onChange }) => (
        <FormControlLabel
          id={"outros"}
          name={"outros"}
          control={
            <Checkbox
              checked={value}
              value={value}
              onChange={onChange}
              style={{ color: theme.palette.primary.main }}
              disabled={visualizacao}
            />
          }
          label="Outros"
        />
      ),
      size: { xs: 12, sm: 6, md: 3 },
    },
    {
      name: "detalheOutros",
      label: "Outros Detalhes",
      renderComponent: ({ value, onChange }) => (
        <TextField
          id="detalheOutros"
          name="detalheOutros"
          label="Outros Detalhes"
          value={value}
          onChange={onChange}
          disabled={visualizacao}
          fullWidth
        />
      ),
      size: { xs: 12, sm: 6, md: 9 },
    },
    {
      name: "areaAtuacaoProfissional",
      label: "*Informe a(s) Área(s) de Atuação do Profissional",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleAreaAtuacaoProfissional}
          label={"*Informe a(s) Área(s) de Atuação do Profissional"}
          placeholder={"Escolha a área de atuação do profissional"}
          list={listaAreaAtuacao.profissional}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "areaAtuacaoEstudante",
      label: "*Informe a(s) Área(s) de Atuação do Estudante",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleAreaAtuacaoEstudante}
          label={"*Informe a(s) Área(s) de Atuação do Estudante"}
          placeholder={"Escolha a área de atuação do estudante"}
          list={listaAreaAtuacao.estudante}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "cursoComplementar",
      label: "*Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoComplementar}
          label={"*Algum Curso Complementar é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso complementar"}
          list={listaCursos.complementar}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "cursoTecnico",
      label: "*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoTecnico}
          label={"*Algum Curso Técnico é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso técnico"}
          list={listaCursos.tecnico}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
    {
      name: "cursoSuperior",
      label: "*Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)",
      renderComponent: ({ value }) => (
        <FormExigenciaVagaTrabalho
          onFieldsChange={handleCursoSuperior}
          label={"*Algum Curso de Nível Superior é Exigido ou Desejável? (Caso sim adicione um ou mais itens)"}
          placeholder={"Escolha o curso de nível superior"}
          list={listaCursos.superior}
          initialValue={value}
          visualizacao={visualizacao}
        />
      ),
      size: { xs: 12 },
    },
  ];

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  // UseEffect para obter os valores iniciais
  useEffect(() => {
    setSelectedUf(initialValues.uf);
    setMunicipios(optionsService.cidadesJSON.filter(cidade => cidade.uf === initialValues.uf));
    setSelectedMunicipio(initialValues.municipio);
  }, [initialValues]);

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    const { name, value } = event.target;
    formRef.current.setValue(name, value);

    setSelectedUf(value);
    setSelectedMunicipio("");
    formRef.current.setValue("municipio", "");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    const { name, value } = event.target;
    formRef.current.setValue(name, value);
    setSelectedMunicipio(event.target.value);
  };

  const handleAreaAtuacaoProfissional = values => {
    formRef.current.setValue("areaAtuacaoProfissional", values);
    console.log(formRef.current.getValues());
  };

  const handleAreaAtuacaoEstudante = values => {
    formRef.current.setValue("areaAtuacaoEstudante", values);
    console.log(formRef.current.getValues());
  };

  const handleCursoComplementar = values => {
    formRef.current.setValue("cursoComplementar", values);
    console.log(formRef.current.getValues());
  };

  const handleCursoTecnico = values => {
    formRef.current.setValue("cursoTecnico", values);
    console.log(formRef.current.getValues());
  };

  const handleCursoSuperior = values => {
    formRef.current.setValue("cursoSuperior", values);
    console.log(formRef.current.getValues());
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      // formRef.current.submitForm();
      console.log(formRef.current.getValues());
    }
  };

  return (
    <>
      <Card
        sx={{
          padding: 2,
          borderRadius: 2,
        }}
      >
        <DynamicForm
          ref={formRef}
          fields={fields}
          initialValues={initialValues}
        />
      </Card>
      {/* Botões */}
      <Grid
        container
        mt={1}
        spacing={1}
        justifyContent="flex-end"
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => navigate("/cursos")}
            fullWidth
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <LoadingButton
            variant="contained"
            type="submit"
            sx={{ gap: "8px" }}
            onClick={handleButtonClick}
          >
            <SaveIcon fontSize="small" />
            <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

VagaTrabalho.propTypes = {
  initialValues: PropTypes.object,
  visualizacao: PropTypes.bool,
  isAdmin: PropTypes.bool,
};

export default VagaTrabalho;
