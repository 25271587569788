import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";

import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Grid, Stack, TextField, Typography } from "@mui/material";

import AuthService from "../../services/auth.service";
import { FormFieldsGrid } from "../formularios/DynamicForm";

const ChangePassword = () => {
  const navigate = useNavigate();

  const token = useParams().token;

  const {
    control,
    handleSubmit,
    // setValue,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { novaSenha: "", confirmNovaSenha: "" } });

  const resetSenha = () => {
    AuthService.postChangePassword(token, getValues().novaSenha)
      .then(response => {
        alert(response.message);
      })
      .catch(error => {
        const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        alert(resMessage);
      })
      .finally(() => {
        navigate("/parceiro_login");
        //TODO: Colocar a pagina de redirecionamento adequada
      });
  };

  // Verifica se a senha tem os requisitos
  const validatePassword = value => {
    const hasUpperCaseLetters = /[A-Z]/.test(value);
    const hasLowerCaseLetters = /[a-z]/.test(value);
    const hasNumbers = /[0-9]/.test(value);
    const hasSpecialChars = /[^a-zA-Z0-9]/.test(value);

    if (!hasUpperCaseLetters) {
      return "Deve conter pelo menos uma letra maiúscula.";
    }
    if (!hasLowerCaseLetters) {
      return "Deve conter pelo menos uma letra minúscula.";
    }
    if (!hasNumbers) {
      return "Deve conter pelo menos um número.";
    }
    if (!hasSpecialChars) {
      return "Deve conter pelo menos um caracter especial.";
    }

    return true;
  };

  // Verifica se os campos de senha são iguais
  const validarIgualdade = value => {
    if (getValues().novaSenha != value) {
      return "As senhas devem ser iguais";
    }
    return true;
  };

  const fields = [
    // Senha
    {
      name: "novaSenha",
      label: "Nova senha",
      validation: {
        required: "Campo de senha é obrigatório",
        minLength: { value: 8, message: "A senha deve ter pelo menos 8 caracteres" },
        validate: validatePassword,
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="novaSenha"
          name="novaSenha"
          label="Nova senha"
          fullWidth
          type={"password"}
          placeholder="Nova senha"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12 },
    },
    //Confirmação
    {
      name: "confirmNovaSenha",
      label: "Confirmar senha",
      validation: {
        validate: validarIgualdade,
      },
      renderComponent: ({ value, onChange, error }) => (
        <TextField
          id="confirmNovaSenha"
          name="confirmNovaSenha"
          label="Confirmar senha"
          fullWidth
          type={"password"}
          placeholder="Confirmar senha"
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      ),
      size: { xs: 12 },
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: 1 }}
        >
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 625,
              height: 1,
              maxHeight: 700,
            }}
          >
            <Typography variant="h3">Alteração de senha</Typography>

            <form onSubmit={handleSubmit(resetSenha)}>
              <FormFieldsGrid
                fields={fields}
                control={control}
                errors={errors}
              />

              {/* Info */}
              <Typography variant="caption">A senha deve cumpir os seguintes requisitos: </Typography>
              <ul>
                <li>
                  <Typography variant="caption">Ter no mínimo 8 caracteres</Typography>
                </li>
                <li>
                  <Typography variant="caption">
                    Incluir uma combinação de letras maiúsculas, minúsculas, números e caracteres especiais (como !,@,#, etc).
                  </Typography>
                </li>
              </ul>

              <Grid
                container
                spacing={1}
                mt={2}
                justifyContent="flex-end"
              >
                <Grid
                  item
                  xs={12}
                >
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    sx={{ gap: "8px" }}
                  >
                    <Typography variant={"BUTTON TEXT"}>SALVAR ALTERAÇÕES</Typography>
                  </LoadingButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => navigate("/parceiro_login")}
                  >
                    CANCELAR
                  </Button>
                </Grid>
              </Grid>
            </form>

            <center>
              <Typography variant="caption">
                Caso o problema persista, entre em contato conosco pelo e-mail{" "}
                <Link
                  component="a"
                  //href=
                  // Incluir o redirecionamento adequado
                  color="primary.main"
                  underline="always"
                  sx={{ ml: 1 }}
                >
                  cgad@mds.gov.br
                </Link>{" "}
              </Typography>
            </center>
          </Card>
        </Stack>
      </Box>
    </>
  );
};

export default ChangePassword;
