import React, { useEffect, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Checkbox, FormControlLabel, Grid, MenuItem, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";

import ReusableSwitch from "../../components/formularios/fields/ReusableSwitch";
import { SelectCidade } from "../../components/formularios/fields/SelectCidade";
import { SelectUF } from "../../components/formularios/fields/SelectUF";
import FormMultipleInsertDouble from "../../components/formularios/FormMultipleInsertDouble";
import optionsService from "../../services/options.service";
import InformationBox from "../boxes/InformationBox";

const valoresIniciais = {
  desejaAbrirNegocio: false,
  primeiraVezAbrirNegocio: false,
  motivos: [],
  setores: "",
  situacaoFutura: "",
  jaParticipouCurso: false,
  gostariaParticiparCurso: false,
  gostariaReceberOrientacao: false,
  cursoParticipado: "",
  instituicaoRealizadora: "",
  gostariaParticiparCursoMotivo: "",
  gostariaReceberOrientacaoMotivo: "",
  conheceEmprestimosEscolaridade: "",
  necessidadeFinancimento: false,
  necessidadeFinancimentoMotivo: "",
  conheceEmprestimos: false,
};

const FormQueroSerEmpreendedor = ({ initialValues = valoresIniciais }) => {
  const [values, setValues] = useState(initialValues);

  const [ufs, setUfs] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedUf, setSelectedUf] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [multiplesInputDouble, setMultiplesInputDouble] = useState([]);
  const theme = useTheme();

  //Tratar os radio
  const [radioSetores, setRadioSetores] = useState(values.setores);

  const handleRadioSetoresChange = event => {
    const { name, value } = event.target;

    // Setando nosso controle próprio do radio (para mostrar na tela)
    setRadioSetores(event.target.value);

    // Repassando isso para o form (para mandar para o backend)
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetchUfs = async () => {
      await optionsService
        .getAllUFs()
        .then(ufsData => {
          setUfs(ufsData);
        })
        .catch(error => {
          console.error("Erro ao obter UFs:", error);
        });
    };
    fetchUfs();
  }, []);

  const fetchCidadesByUf = async (ufSigla, setCidadesFunction) => {
    try {
      const cidadesData = await optionsService.getCidadesFromUF(ufSigla);
      setCidadesFunction(cidadesData);
    } catch (error) {
      console.error("Erro ao obter cidades:", error);
    }
  };

  const handleSelectUf = event => {
    // eslint-disable-next-line no-unused-vars
    const { name, value } = event.target;

    setSelectedUf(value);
    setSelectedMunicipio("");
    fetchCidadesByUf(event.target.value, setMunicipios);
  };

  const handleSelectMunicipio = event => {
    setSelectedMunicipio(event.target.value);
  };

  const handleSwitchChange = event => {
    const { name, checked } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: checked,
    }));
  };

  const handleCheckboxChange = event => {
    const { name, checked, value } = event.target;
    setValues(prevValues => {
      const newArray = checked ? [...prevValues[name], value] : prevValues[name].filter(item => item !== value);
      return { ...prevValues, [name]: newArray };
    });
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleChange = event => {
    const { name, value } = event.target.value;
    setValues({ ...values, [name]: value });
  };

  const handleLogMultipleInputsDouble = () => {
    console.log("multiple inputs -> ", multiplesInputDouble);
  };

  const handleMultipleInputsDouble = values => {
    setMultiplesInputDouble(values);
  };

  const handleSubmit = values => {
    console.log("SEND OBJ -> ", values);
  };

  return (
    <>
      <div>
        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre o negócio
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>Você deseja abrir seu próprio negócio?</p>
                <ReusableSwitch
                  id="desejaAbrirNegocio"
                  name="desejaAbrirNegocio"
                  value={values.desejaAbrirNegocio}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>
                  É a primeira vez que você vai abrir o seu próprio negócio?
                </p>
                <ReusableSwitch
                  id="primeiraVezAbrirNegocio"
                  name="primeiraVezAbrirNegocio"
                  value={values.primeiraVezAbrirNegocio}
                  onChange={handleSwitchChange}
                  disabled={!values.desejaAbrirNegocio}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Qual(is) o(s) motivo(s) que faz você desejar abrir seu próprio negócio?
                </p>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("desempregado")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="desempregado"
                      />
                    }
                    label="Estou desempregado, preciso de alguma atividade que me gere renda"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("sonho")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="sonho"
                      />
                    }
                    label="É um sonho que quero realizar"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("queroSerPatrao")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="queroSerPatrao"
                      />
                    }
                    label="Quero ser meu próprio patrão"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("queroTerExperienciaEmpreender")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="queroTerExperienciaEmpreender"
                      />
                    }
                    label="Quero ter a experiência de empreender um negócio"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("queroEmpreenderNovamente")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="queroEmpreenderNovamente"
                      />
                    }
                    label="Quero empreender novamente"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("projetoFamiliar")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="projetoFamiliar"
                      />
                    }
                    label="É um projeto familiar, precisam da minha participação"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.motivos.includes("empregado")}
                        onChange={handleCheckboxChange}
                        name="motivos"
                        value="empregado"
                      />
                    }
                    label="Apesar de estar empregado, preciso de um complemento da minha renda"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                    O seu futuro negócio está mais próximo de qual dos setores abaixo?
                  </p>
                  <FormGroup>
                    <RadioGroup
                      name="setores"
                      onChange={handleRadioSetoresChange}
                      value={radioSetores}
                    >
                      <FormControlLabel
                        value="industria"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={radioSetores === "industria"}
                          />
                        }
                        label="Indústria (Você fabrica algo?)"
                      />
                      <FormControlLabel
                        value="comercio"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={radioSetores === "comercio"}
                          />
                        }
                        label="Comércio (Você vende produtos?)"
                      />
                      <FormControlLabel
                        value="servicos"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={radioSetores === "servicos"}
                          />
                        }
                        label="Serviços (Você é um prestador de serviços - hidráulica, pintura, alimentação, etc.)?"
                      />
                      <FormControlLabel
                        value="outros"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={radioSetores === "outros"}
                          />
                        }
                        label="Outros"
                      />
                    </RadioGroup>
                  </FormGroup>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Qual a situação do seu negócio?</p>
                  <FormGroup>
                    <RadioGroup
                      name="situacaoFutura"
                      onChange={handleChange}
                      value={values.situacaoFutura} // TODO: arrumar
                    >
                      <FormControlLabel
                        value="informal"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.situacaoFutura === "informal"}
                          />
                        }
                        label="Informal"
                      />
                      <FormControlLabel
                        value="formal"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.situacaoFutura === "formal"}
                          />
                        }
                        label="Formal – microempresa"
                      />
                      <FormControlLabel
                        value="formal-outros"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.situacaoFutura === "formal-outros"}
                          />
                        }
                        label="Formal – Outros portes"
                      />
                      <FormControlLabel
                        value="formal-mei"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.situacaoFutura === "formal-mei"}
                          />
                        }
                        label="Formal – Microempreendedor Individual – MEI"
                      />
                    </RadioGroup>
                  </FormGroup>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você já participou de algum curso sobre empreendedorismo, microcrédito, educação financeira?
                </p>
                <ReusableSwitch
                  id="jaParticipouCurso"
                  name="jaParticipouCurso"
                  value={values.jaParticipouCurso}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
                <Grid
                  item
                  container
                  spacing={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <FormMultipleInsertDouble
                      initialValue1={"initial 1"}
                      placeHolder1={"Curso"}
                      label1={"Qual(is) Curso(s)?"}
                      initialValue2={"initial 2"}
                      placeHolder2={"Instituição"}
                      label2={"Qual a Instituição realizadora?"}
                      onFieldsChange={handleMultipleInputsDouble}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleLogMultipleInputsDouble}
                      sx={{ mt: 1 }}
                    >
                      Confirmar Cursos
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você gostaria de participar de algum curso sobre empreendedorismo, microcrédito, educação financeira, de outros assuntos
                  para melhor gerir o seu negócio?
                </p>
                <ReusableSwitch
                  id="gostariaParticiparCurso"
                  name="gostariaParticiparCurso"
                  value={values.gostariaParticiparCurso}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
                {values.gostariaParticiparCurso && (
                  <TextField
                    label="Por que motivo?"
                    name="gostariaParticiparCursoMotivo"
                    value={values.gostariaParticiparCursoMotivo}
                    onChange={handleInputChange}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você gostaria de receber orientação sobre como administrar seu negócio?
                </p>
                <ReusableSwitch
                  id="gostariaReceberOrientacao"
                  name="gostariaReceberOrientacao"
                  value={values.gostariaReceberOrientacao}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
                {values.gostariaReceberOrientacao && (
                  <TextField
                    label="Por que motivo?"
                    name="gostariaReceberOrientacaoMotivo"
                    value={values.gostariaReceberOrientacaoMotivo}
                    onChange={handleInputChange}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ border: "1px solid grey" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={{ fontFamily: "Rawline Bold", color: "primary.main" }}
          >
            <InfoIcon sx={{ marginRight: "8px", color: "primary.main" }} />
            Sobre você
          </AccordionSummary>
          <AccordionDetails>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginBottom: "5px" }}>
                  Você tem necessidade de um financiamento/empréstimo de microcrédito?
                </p>
                <ReusableSwitch
                  id="necessidadeFinancimento"
                  name="necessidadeFinancimento"
                  value={values.necessidadeFinancimento}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
                {values.necessidadeFinancimento && (
                  <TextField
                    label="Por que motivo?"
                    id="necessidadeFinancimentoMotivo"
                    name="necessidadeFinancimentoMotivo"
                    value={values.necessidadeFinancimentoMotivo}
                    onChange={handleInputChange}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>
                  Você conhece os empréstimos para grupos de microempreendedores que utilizam o chamado aval solidário como garantia de
                  pagamento do empréstimo?
                </p>
                <ReusableSwitch
                  id="conheceEmprestimos"
                  name="conheceEmprestimos"
                  value={values.conheceEmprestimos}
                  onChange={handleSwitchChange}
                  disabled={false}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Nome completo"
                  name="conheceEmprestimosNomeCompleto"
                  value={values.conheceEmprestimosNomeCompleto}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Data de nascimento"
                  name="conheceEmprestimosDataNascimento"
                  value={values.conheceEmprestimosDataNascimento}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="RG"
                  name="conheceEmprestimosRg"
                  value={values.conheceEmprestimosRg}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="CPF"
                  name="conheceEmprestimosCpf"
                  value={values.conheceEmprestimosCpf}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <div>
                  <p style={{ fontFamily: "rawline Bold", marginTop: "10px", marginBottom: "5px" }}>Gênero</p>
                  <FormGroup>
                    <RadioGroup
                      name="genero"
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="Feminino"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.conheceEmprestimosSexo === "feminino"}
                          />
                        }
                        label="Feminino"
                      />
                      <FormControlLabel
                        value="Masculino"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.conheceEmprestimosSexo === "masculino"}
                          />
                        }
                        label="Masculino"
                      />
                      <FormControlLabel
                        value="Prefiro não informar"
                        control={
                          <Radio
                            style={{ color: theme.palette.primary.main, fontFamily: "Rawline Regular", marginRight: "8px" }}
                            checked={values.conheceEmprestimosSexo === "Prefiro não informar"}
                          />
                        }
                        label="Prefiro não informar"
                      />
                    </RadioGroup>
                  </FormGroup>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  select
                  label="Escolaridade"
                  name="conheceEmprestimosEscolaridade"
                  value={values.conheceEmprestimosEscolaridade}
                  onChange={handleInputChange}
                  fullWidth
                >
                  <MenuItem value="Fundamental">Fundamental</MenuItem>
                  <MenuItem value="Médio">Médio</MenuItem>
                  <MenuItem value="Superior">Superior</MenuItem>
                  <MenuItem value="Pós-graduação">Pós-graduação</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="CEP"
                  name="conheceEmprestimosCep"
                  value={values.conheceEmprestimosCep}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectUF
                  idSelect="uf"
                  nameSelect="uf"
                  value={values.uf}
                  ufs={ufs}
                  uf={selectedUf}
                  label="UF"
                  handleSelectUf={event => handleSelectUf(event)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <SelectCidade
                  idSelect="municipio"
                  nameSelect="municipio"
                  value={values.municipio}
                  cidades={municipios}
                  cidade={selectedMunicipio}
                  label="Município"
                  handleSelectCidade={event => handleSelectMunicipio(event)}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Bairro/Distrito"
                  name="conheceEmprestimosBairroDistrito"
                  value={values.conheceEmprestimosBairroDistrito}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
              >
                <TextField
                  label="Logradouro"
                  name="conheceEmprestimosEndereco"
                  value={values.conheceEmprestimosEndereco}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Número"
                  name="conheceEmprestimosNumero"
                  value={values.conheceEmprestimosNumero}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Complemento"
                  name="conheceEmprestimosComplemento"
                  value={values.conheceEmprestimosComplemento}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
              >
                <TextField
                  label="Telefone de contato"
                  name="conheceEmprestimosTelefoneContato"
                  value={values.conheceEmprestimosTelefoneContato}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <TextField
                  label="E-mail"
                  name="conheceEmprestimosEmail"
                  value={values.conheceEmprestimosEmail}
                  onChange={handleInputChange}
                  fullWidth
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      <InformationBox
        title="Atenção:"
        message="Confira todas as respostas antes de clicar no botão 'Salvar Respostas'. Após salvar, não será permitido alterar as respostas."
      />

      <Grid
        container
        spacing={1}
        justifyContent="flex-end"
        mt={2}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={() => console.log("cancelar")}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium" }}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={() => handleSubmit(values)}
            sx={{ gap: "8px" }}
            style={{ fontFamily: "Rawline Medium" }}
          >
            <SaveIcon></SaveIcon>
            Salvar resposta
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

FormQueroSerEmpreendedor.propTypes = {
  initialValues: PropTypes.object,
};

export default FormQueroSerEmpreendedor;
