import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import FileUpload from "../inputs/fileUpload";

const AdminTrilha = ({ initialValues, onSubmit, editar = false, visualizar = false }) => {
  const [localFiles, setLocalFiles] = useState(initialValues.anexo);

  const handleFiles = (newFiles, setFieldValue) => {
    setLocalFiles(newFiles);
    setFieldValue("anexo", newFiles);
  };

  const {
    //register,
    control,
    handleSubmit,
    setValue,
    //watch,  // Usado para observar quando algum valor é adicionado a um campo
    formState: { isSubmitting, errors },
    //{ errors },  Responsável por retornar os erros de validação
  } = useForm({
    defaultValues: initialValues,
    mode: "onSubmit", // or "onBlur", "onChange", depending on when you want validation to trigger
  });

  useEffect(() => {
    if (initialValues.situacao != "Aprovado" && initialValues.situcao != "Inativo") {
      setValue("situacao", "none");
    }
  }, [initialValues]);

  // const situacaoInicial = initialValues.situacao || "none";
  // const situcao = watch('situacao');

  return (
    <Stack spacing={1}>
      <Card
        color="#ffffff"
        sx={{
          borderRadius: "8px",
          padding: "16px",
          mt: "24px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Campos */}
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
            >
              <Typography variant="subtitle1">Dados da trilha</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={visualizar ? 8 : 12}
            >
              <Controller
                name="nome"
                control={control}
                rules={{ required: "Nome da Trilha é obrigatório" }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    fullWidth
                    id="nome"
                    label="Nome da Trilha"
                    placeholder="Nome da Trilha"
                    value={value}
                    onChange={onChange}
                    disabled={visualizar}
                    error={!!errors.nome}
                    helperText={errors.nome?.message}
                  />
                )}
              />
            </Grid>

            {visualizar && (
              <Grid
                item
                xs={12}
                sm={4}
              >
                <FormGroup>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Controller
                      name="dataUltimaModificacao"
                      control={control}
                      render={({ field: { value } }) => (
                        <DatePicker
                          label="Data da Última Modificação"
                          id="dataUltimaModificacao"
                          value={dayjs(value)}
                          format="DD/MM/YYYY"
                          onChange={value => dayjs(value)}
                          disabled={visualizar}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormGroup>
              </Grid>
            )}

            <Grid
              item
              xs={12}
            >
              <Controller
                name="descricao"
                control={control}
                rules={{ required: "Descrição da Trilha é obrigatória" }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    multiline
                    fullWidth
                    rows={3}
                    id="descricao"
                    name="descricao"
                    value={value}
                    onChange={onChange}
                    label="Descrição da Trilha"
                    placeholder="Descrição da Trilha"
                    disabled={visualizar}
                    error={!!errors.descricao}
                    helperText={errors.descricao?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            mt={2}
            mb={2}
          >
            <Controller
              name="arquivos"
              control={control}
              render={() => (
                <FileUpload
                  initialValues={localFiles}
                  maxFiles={1}
                  fileType={["image/jpeg", "image/jpg", "image/png", "image/gif"]}
                  onFieldsChange={fields => handleFiles(fields, setValue)}
                  visualizar={visualizar}
                  showFiles={editar || visualizar}
                  // TODO: adicionar erro
                />
              )}
            />
          </Grid>

          {editar && !visualizar && (
            <Grid
              item
              xs={12}
              sm={12}
            >
              <Controller
                name="situacao"
                control={control}
                rules={{ required: "Situação é obrigatória" }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth>
                    <InputLabel id="label-situacao">Situação</InputLabel>
                    <Select
                      id="situacao"
                      labelId="label-situacao"
                      value={value}
                      onChange={onChange}
                      error={!!errors.situacao}
                    >
                      <MenuItem
                        value="none"
                        disabled
                      >
                        Situação
                      </MenuItem>
                      <MenuItem value="Aprovado">Ativo</MenuItem>
                      <MenuItem value="Inativo">Inativo</MenuItem>
                    </Select>
                    {errors.situacao && <FormHelperText error>{errors.situacao.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
          )}

          {/* Campos exclusivos da tela de visualizar */}
          {visualizar && (
            <Grid
              container
              spacing={2}
            >
              <Grid
                item
                xs={12}
              >
                <Controller
                  name="numeroDeCursos"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      id="numeroDeCursos"
                      name="numeroDeCursos"
                      value={value}
                      onChange={onChange}
                      label="Total de Cursos"
                      placeholder="Total de Cursos"
                      disabled={visualizar}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Controller
                  name="relacao"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      id="relacao"
                      name="relacao"
                      value={value}
                      onChange={onChange}
                      label="Relação de Cursos"
                      placeholder="Relação de Cursos"
                      disabled={visualizar}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}

          {/* Botões de salvar/cancelar */}
          {!visualizar && (
            <Box sx={{ flexGrow: 1, mt: "16px" }}>
              <Grid
                container
                spacing={1}
                justifyContent="flex-end"
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => window.history.back()}
                  >
                    <Typography variant={"BUTTON TEXT"}>CANCELAR</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    fullWidth
                    sx={{ gap: "8px" }}
                  >
                    {/* {loading && <span className="spinner-border spinner-border-sm"></span>} */}
                    <SaveIcon fontSize="small" />
                    <Typography variant={"BUTTON TEXT"}>SALVAR</Typography>
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          )}
        </form>
      </Card>
    </Stack>
  );
};

AdminTrilha.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.any,
  editar: PropTypes.bool,
  visualizar: PropTypes.bool,
};

export default AdminTrilha;
