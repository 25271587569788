import transformDadosEmpresaComplementar from "../utils/parceiroEmpresaComplementarUtils";
import { transformarDadosUnicaEmpresa, transformEmpresasComDetalhes } from "../utils/parceiroEmpresaUtils";
import axiosInstance from "./axiosConfig";
import API_URL from "./config";

/**
 * Recupera um parceiro usando o token de acesso fornecido e o ID fornecido.
 *
 * @param {number} id - O ID do parceiro a ser recuperado
 * @return {Promise} Uma Promessa que resolve para os dados do parceiro recuperado
 */
const getParceiroData = () => {
  return axiosInstance
    .get(API_URL + "v1/parceiros/index/", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      // console.error("Error fetching parceiro data:", error);
      throw error;
    });
};

/**
 * Cadastra uma nova empresa parceira.
 *
 * @param {Object} data - Os dados da empresa parceira a serem cadastrados.
 * @return {Promise<string>} Uma promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante o cadastro da empresa parceira.
 */
const postCadastrarEmpresa = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      console.log("Service parceiro");
      return response.data.empresa_id;
    })
    .catch(error => {
      console.error("Error cadastrando empresa parceira:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras.
 */
const getEmpresasParceiras = () => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresas`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error fetching empresas parceiras:", error);
      throw error;
    });
};

/**
 * Recupera os detalhes de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser recuperada.
 * @return {Promise<Object>} Uma Promessa que resolve para os detalhes da empresa parceira recuperada.
 * @throws {Error} Se ocorrer algum erro durante a recuperação dos detalhes da empresa parceira.
 */
const getEmpresaData = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return transformarDadosUnicaEmpresa(response.data);
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

/**
 * Recupera uma lista de empresas parceiras com seus detalhes.
 *
 * @return {Promise<Array>} Uma promessa que resolve para um array de empresas parceiras com seus detalhes.
 */
const getEmpresasParceirasComDetalhes = async () => {
  try {
    const response = await getEmpresasParceiras();

    // Mapear cada empresa para buscar detalhes individuais
    const empresas = Object.values(response);

    // Map each empresa to fetch individual details
    const detalhesPromises = empresas.map(empresa => {
      return getEmpresaData(empresa.pk_empresa_parceira);
    });

    // Esperar por todas as chamadas de detalhes
    const detalhes = await Promise.all(detalhesPromises);

    // Combinar dados brutos com detalhes
    const empresasComDetalhes = empresas.map((empresa, index) => ({
      ...empresa,
      detalhes: detalhes[index],
    }));

    return transformEmpresasComDetalhes(empresasComDetalhes);
  } catch (error) {
    console.error("Error fetching empresas parceiras:", error);
    throw error;
  }
};

/**
 * Exclui uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser excluída.
 * @param {string} motivo - O motivo da exclusão da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a exclusão da empresa parceira.
 */
const excluiEmpresaParceira = (id, motivo) => {
  return axiosInstance
    .delete(API_URL + `v1/parceiros/empresa/exclui/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      data: {
        motivo: motivo,
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error deleting empresa parceira:", error);
      throw error;
    });
};

/**
 * Atualiza os dados de uma empresa parceira usando o ID fornecido.
 *
 * @param {number} id - O ID da empresa parceira a ser atualizada.
 * @param {Object} empresaData - Os novos dados da empresa parceira.
 * @return {Promise<string>} Uma Promessa que resolve para a mensagem de sucesso da operação.
 * @throws {Error} Se ocorrer algum erro durante a atualização dos dados da empresa parceira.
 */
const atualizaEmpresaParceira = (id, empresaData) => {
  return axiosInstance
    .put(API_URL + `v1/parceiros/empresa/atualiza/${id}`, empresaData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.error("Error updating empresa parceira:", error);
      throw error;
    });
};

const postCadastrarEmpresaComplementar = data => {
  return axiosInstance
    .post(API_URL + `v1/parceiros/empresa_complementar/cadastro`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      return response.data.message;
    })
    .catch(error => {
      console.error("Error cadastrando empresa complementar parceira:", error);
      throw error;
    });
};

const getEmpresaComplementar = id => {
  return axiosInstance
    .get(API_URL + `v1/parceiros/empresa_complementar/${id}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
    .then(response => {
      const dados = transformDadosEmpresaComplementar(response.data);
      return dados;
    })
    .catch(error => {
      console.error("Error fetching empresa parceira details:", error);
      throw error;
    });
};

const parceiroService = {
  getParceiroData,
  getEmpresaData,
  postCadastrarEmpresa,
  getEmpresasParceiras,
  excluiEmpresaParceira,
  atualizaEmpresaParceira,
  getEmpresasParceirasComDetalhes,
  postCadastrarEmpresaComplementar,
  getEmpresaComplementar,
};

export default parceiroService;
